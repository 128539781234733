//#region ******************************************************* //
/*
 *This is the Router called from App.js
 */
//#endregion ************************************************* //

import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "@cbmisorg/client-app";

import SecureRoute from "../appHelper/routing/SecureRoute";
import objAppRouting from "../appHelper/routing/appRouting";
import { ChangFontSize } from "../components/public/settings/ChangFontSize";


////// old 
import RouteLandingPage from "../components/public/LandingPage/RouteLandingPage";
import Events from "../components/public/LandingPage/events/Events";
import Event from "../components/public/LandingPage/events/Event";
import RouteSettings from "../components/public/settings/RouteSettings";
import Members from "../components/public/LandingPage/member/Members";
import RouteMessageOfSheikhMuhammad from "../components/public/LandingPage/MessageOfSheikhMuhammad/RouteMessageOfSheikhMuhammad";
import { getAppLocalStorage } from "../appHelper/appFunctions";
import ManageActivates from "../components/stakeholders/shared/manageActivates/ManageActivates";
import SaveActivity from "../components/stakeholders/shared/manageActivates/SaveActivity";
import RouteContact from "../components/stakeholders/shared/contact/RouteContact";
import RouteViewContact from "../components/stakeholders/shared/contact/RouteViewContact";
import RouteSubscription from "../components/stakeholders/shared/subscription/RouteSubscription";
import ManageSystem from "../components/stakeholders/admin/manageSystrm/ManageSystem";
import RouteRequirementInfo from "../components/public/requirementInfo/RouteRequirementInfo";
import WaitingPage from "../components/public/waitingPage/WaitingPage";
import RouteaMnageMembers from "../components/stakeholders/shared/manageMembers/RouteaMnageMembers";
import RouteViewMember from "../components/stakeholders/shared/manageMembers/RouteViewMember";
import Advisor from "../components/stakeholders/shared/advisor/Advisor";
import ActivatesArchaic from "../components/stakeholders/shared/manageActivates/ActivatesArchaic";
import RouteMngRequestedActive from "../components/stakeholders/shared/mngRequesteAaitoSubscrip/RouteMngRequestedActive";
import RouteViewRequestedActive from "../components/stakeholders/shared/mngRequesteAaitoSubscrip/RouteViewRequestedActive";
import RouteaMnageReqTraining from "../components/stakeholders/admin/mngReqTraining/RouteaMnageReqTraining";
import RouteViewReqTraining from "../components/stakeholders/admin/mngReqTraining/RouteViewReqTraining";
import WaitingTrainingPage from "../components/public/waitingPage/WaitingTrainingPage";
import RouteMngPartner from "../components/stakeholders/shared/mngPartner/RouteMngPartner";
import Partners from "../components/public/LandingPage/partners/Partners";

import MngAnnouncement from "../components/stakeholders/admin/mngAnnouncement";
import MngMeeting from "../components/stakeholders/admin/mngMeeting";
import MngSupportTrans from "../components/stakeholders/admin/mngSupportTrans";

import ForgetPassword from "../components/public/forgetPasswoed/ForgetPassword";
import Support from "../components/public/LandingPage/support/Support";
import Statistic from "../components/stakeholders/shared/statistic/Statistic";
import RouteMngCategory from "../components/stakeholders/admin/mngCategory/RouteMngCategory";
import MngPotentialSponsorship from "../components/stakeholders/shared/mngPotentialSponsorship";
import MngSuggestedSponsor from "../components/stakeholders/shared/mngPotentialSponsorship/mngSuggestedSponsor/MngSuggestedSponsor";
import RouteTraining from "../components/stakeholders/shared/mngTraining/RouteTraining";
import RoutmMangTrainingInfo from "../components/stakeholders/shared/mngTraining/RoutmMangTrainingInfo";
import TrainingVideo from "../components/public/initiative/TrainingVideo";
import AdvertisingVideoManagement from "../components/stakeholders/admin/advertisingVideoManagement/AdvertisingVideoManagement";

import RegisteredActivities from "../components/stakeholders/admin/registeredActivities";
import appRouting from "../../client/configuration/routing/appRouting";

import RouteLogin from "../components/public/login/RouteLogin";

// new-v
//#region public
import Registration from "../../client/component/public/registration"
import Initiative from "../../client/component/public/Initiative";
import Profile from "../../client/component/stakeholders/shared/profile";
import Page404 from "../../client/component/public/Page404";
import Media from "../../client/component/public/media";
import ECertificate from "../../client/component/public/eCertificate/eCertificate.view";
import ECertificatePreview from "../../client/component/public/eCertificate/eCertificatePreview.view";
import RegForumActivity from "../../client/component/public/regForum/regForum.view";
import ForumBadgeActivity from "../../client/component/public/forumBadge/forumBadge.view";
//#endregion

//#region stackholder
import Account from "../../client/component/stakeholders/shared/account"
import Supportinfo from "../../client/component/stakeholders/member/supportinfo";
import AttendeeManagement from "../components/stakeholders/admin/AttendeeManagement";
import MngRegForum from "../../client/component/stakeholders/admin/mngRegForum/mngRegForum.view";
import ManageMedia from "../../client/component/stakeholders/admin/manageMedia";
import TrainingRegistration from "../../client/component/public/trainingRegistration";
import MngUsers from "../../client/component/stakeholders/admin/mngUsers";
import MngSurvey from "../../client/component/stakeholders/admin/mngSurvey";
import MngSurveySave from "../../client/component/stakeholders/admin/mngSurveySave";
import SurveyMonkey from "../../client/component/public/surveymonkey";
import SurveymonkeyThankView from "../../client/component/public/surveymonkey/surveymonkeyThank.view";
import ResearcherRegistration from "../../client/component/public/researcherRegistration";
import ManageInitiative from "../../client/component/stakeholders/admin/manageInitiative";
//#endregion

const locationState = getAppLocalStorage();
ChangFontSize(locationState?.clientInfo?.appFontSize);

function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<React.Fragment />}>
        <Route path={objAppRouting.Public_LandingPage.url} element={<SecureRoute><RouteLandingPage /></SecureRoute>} />
        <Route path={objAppRouting.Public_Team.url} element={<SecureRoute><Members /></SecureRoute>} />

        <Route path={objAppRouting.Public_Events.url} element={<SecureRoute><Events /></SecureRoute>} />
        <Route path={objAppRouting.Public_Event.url} element={<SecureRoute><Event /></SecureRoute>} />

        <Route path={objAppRouting.Public_Support.url} element={<SecureRoute><Support /></SecureRoute>} />

        <Route path={objAppRouting.Partner.url} element={<SecureRoute><Partners /></SecureRoute>} />

        <Route path={objAppRouting.Public_Settings.url} element={<SecureRoute><RouteSettings /></SecureRoute>} />

        <Route path={objAppRouting.RequirementInfo.url} element={<SecureRoute><RouteRequirementInfo /></SecureRoute>} />
        <Route path={objAppRouting.Waiting.url} element={<SecureRoute><WaitingPage /></SecureRoute>} />
        <Route path={objAppRouting.WaitingTraining.url} element={<SecureRoute><WaitingTrainingPage /></SecureRoute>} />



        <Route path={objAppRouting.Public_SheikMessage.url} element={<SecureRoute><RouteMessageOfSheikhMuhammad /></SecureRoute>} />


        <Route path={objAppRouting.ManageActivates.url} element={<SecureRoute><ManageActivates /></SecureRoute>} />
        <Route path={objAppRouting.ManageActivates_Archaic.url} element={<SecureRoute><ActivatesArchaic /></SecureRoute>} />
        <Route path={objAppRouting.SaveActivates.url} element={<SecureRoute><SaveActivity /></SecureRoute>} />

        <Route path={objAppRouting.Contact.url} element={<SecureRoute><RouteContact /></SecureRoute>} />
        <Route path={objAppRouting.viewEmail.url} element={<SecureRoute><RouteViewContact /></SecureRoute>} />
        <Route path={objAppRouting.Subscription.url} element={<SecureRoute><RouteSubscription /></SecureRoute>} />


        <Route path={objAppRouting.Mng_Potential_Partner.url} element={<SecureRoute><MngPotentialSponsorship /></SecureRoute>} />
        <Route path={objAppRouting.Manage_RequestActive.url} element={<SecureRoute><RouteMngRequestedActive /></SecureRoute>} />
        <Route path={objAppRouting.View_RequestActive.url} element={<SecureRoute><RouteViewRequestedActive /></SecureRoute>} />
                
        <Route path={objAppRouting.Manage_Training.url} element={<SecureRoute><RouteTraining /></SecureRoute>} />
        <Route path={objAppRouting.manage_Taining_info.url} element={<SecureRoute><RoutmMangTrainingInfo /></SecureRoute>} />
        <Route path={objAppRouting.Manage_Partner.url} element={<SecureRoute><RouteMngPartner /></SecureRoute>} />
        <Route path={objAppRouting.Manage_Partner_Support_Trans.url} element={<SecureRoute><MngSupportTrans /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Join_Symposium.url} element={<SecureRoute><RouteaMnageReqTraining /></SecureRoute>} />
        <Route path={objAppRouting.View_Join_Symposium.url} element={<SecureRoute><RouteViewReqTraining /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Suggested_Sponsor.url} element={<SecureRoute><MngSuggestedSponsor /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Suggested_SponsorwithParm.url} element={<SecureRoute><MngSuggestedSponsor /></SecureRoute>} />

        <Route path={objAppRouting.ManageSystem.url} element={<SecureRoute><ManageSystem /></SecureRoute>} />
        <Route path={objAppRouting.AdvertisingVideoManagement.url} element={<SecureRoute><AdvertisingVideoManagement /></SecureRoute>} />
        <Route path={objAppRouting.manageMembers.url} element={<SecureRoute><RouteaMnageMembers /></SecureRoute>} />
        <Route path={objAppRouting.viewMember.url} element={<SecureRoute><RouteViewMember /></SecureRoute>} />
        <Route path={objAppRouting.ManageCategories.url} element={<SecureRoute><RouteMngCategory /></SecureRoute>} />
        <Route path={objAppRouting.CardMember.url} element={<SecureRoute><Advisor /></SecureRoute>} />
        <Route path={objAppRouting.MngAnnouncement.url} element={<SecureRoute><MngAnnouncement /></SecureRoute>} />
        <Route path={objAppRouting.MngMeeting.url} element={<SecureRoute><MngMeeting /></SecureRoute>} />

        <Route path={objAppRouting.ManageStatistic.url} element={<SecureRoute><Statistic /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Registered_Activities.url} element={<SecureRoute><RegisteredActivities /></SecureRoute>} />
        <Route path={objAppRouting.AttendeeManagement.url} element={<SecureRoute><AttendeeManagement /></SecureRoute>} />


        <Route path={objAppRouting.public_Training_Vedio.url} element={<SecureRoute><TrainingVideo /></SecureRoute>} />



      {/* new V */}
      <Route path={appRouting?.Register?.url} element={<SecureRoute><Registration /></SecureRoute>} />
      <Route path={appRouting?.Registration?.url} element={<SecureRoute><Registration /></SecureRoute>} />
      <Route path={appRouting?.TrainingRegistration?.url} element={<SecureRoute><TrainingRegistration /></SecureRoute>} />
      <Route path={appRouting?.ResearcherRegistration?.url} element={<SecureRoute><ResearcherRegistration /></SecureRoute>} />
      <Route path={appRouting.Public_Login.url} element={<SecureRoute><RouteLogin /></SecureRoute>} />
      <Route path={objAppRouting.Public_RestPassword.url} element={<SecureRoute><ForgetPassword /></SecureRoute>} />
      <Route path={appRouting?.page404?.url} element={<SecureRoute><Page404 /></SecureRoute>} />
      <Route path={appRouting.media.url} element={<SecureRoute><Media /></SecureRoute>} />

      <Route path={appRouting?.RegForum?.url} element={<SecureRoute><RegForumActivity /></SecureRoute>} />
      <Route path={appRouting?.ForumBadge?.url} element={<SecureRoute><ForumBadgeActivity /></SecureRoute>} />
      <Route path={appRouting?.surveymonkey?.url} element={<SecureRoute><SurveyMonkey /></SecureRoute>} />
      <Route path={appRouting?.surveymonkeyWithAnswerID?.url} element={<SecureRoute><SurveyMonkey /></SecureRoute>} />
      <Route path={appRouting?.surveyThank?.url} element={<SecureRoute><SurveymonkeyThankView /></SecureRoute>} />

      <Route path={appRouting.Public_Initiative.url} element={<SecureRoute><Initiative /></SecureRoute>} />

      <Route path={appRouting?.Account?.url} element={<SecureRoute><Account /></SecureRoute>} />
      <Route path={appRouting?.Profile?.url} element={<SecureRoute><Profile /></SecureRoute>} />
      <Route path={appRouting?.SponsorProfile?.url} element={<SecureRoute><Supportinfo /></SecureRoute>} />

      <Route path={appRouting?.ECertificate?.url} element={<SecureRoute><ECertificatePreview /></SecureRoute>} />
      <Route path={appRouting?.Generate_Certificate?.url} element={<SecureRoute><ECertificate /></SecureRoute>} />

      <Route path={appRouting.MngUsers.url} element={<SecureRoute><MngUsers /></SecureRoute>} />
      <Route path={appRouting.ManageMedia.url} element={<SecureRoute><ManageMedia /></SecureRoute>} />
      <Route path={appRouting?.MngRegForum?.url} element={<SecureRoute><MngRegForum /></SecureRoute>} />
      <Route path={appRouting?.MngSurvey?.url} element={<SecureRoute><MngSurvey /></SecureRoute>} />
      <Route path={appRouting?.MngSurveySave?.url} element={<SecureRoute><MngSurveySave /></SecureRoute>} />

      <Route path={appRouting?.ManageInitiative?.url} element={<SecureRoute><ManageInitiative /></SecureRoute>} />

      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default Router;
