"use client";
import React, { useEffect } from "react";
import { App_Setting_Mode } from "../../../configuration/appVariables";
import { App_info_Color } from "../../../util/appStyle";
import { useParams } from "@cbmisorg/router";
import { getAppLocalStorage } from "../../../util/appFunctions";
import { Loader } from "@cbmisorg/material";
// import { useApp } from "../../../configuration/contextapi/context";

const proLink = "https://initiative.cbmis.com/web/";
const testLink = "https://initiative.cbmis.click/web/";
const localhostLink = "http://localhost:3001/web/";

const lstInitiativePath = ["kw", "jo", "eg"];
const type = "pwa";

function InitiativeView() {
  // const {appState}=useApp()
  const { strTrainingType } = useParams();
  const aaiotLocalStorage = getAppLocalStorage();

  useEffect(() => {
    if (!lstInitiativePath.includes(strTrainingType)) {
      // navigate(objAppRouting.Public_LandingPage.url)
    }
  }, []);

  useEffect(() => {}, []);

  if (!lstInitiativePath.includes(strTrainingType)) {
    return <Loader backdrop={true} color={App_info_Color} />;
  }
  return (
    <iframe
      id="initiativeIfream"
      src={`${App_Setting_Mode === "production" ? proLink : App_Setting_Mode === "testing" ? testLink : localhostLink}${type}/${strTrainingType}?lang=${
        aaiotLocalStorage?.clientInfo?.strDataLanguage
      }&noenroll=${aaiotLocalStorage?.userInfo?.blnIsStudent}`}
      frameBorder="0"
      height="100vh"
      width="100vw"
      style={{ overflow: "hidden", border: 0, width: "100vw", height: "99vh" }}
    ></iframe>
  );
}

export default InitiativeView;
