import { Alert, Confirmation } from "@cbmisorg/client-app";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
// import { aaiot_System_ID } from "../../../../../appHelper/appVariables";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import * as appVariables from "../../../../../appHelper/appVariables";

const tblUser = generateQueries("tblUser");

const strPageInfo = "@src/components/stakeholders/shared/mngRequestedJoin/controler/routeMngRequestedJoin.controller.js";

export function routeMngRequestedJoinCtrl({ appState, appDispatch, strTrainingType }) {
  const lang = appState?.clientInfo?.strLanguage;
  // const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;
  const allLang = { eng: "All", arb: "الجميع" };
  const strUserStatusKey = "bigUserStatusID";

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fldSort, fields, strTrainingType }) =>
      async () => {
        try {

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang]);
            return;
          }

          setIsLoading(true);
          state.blnIsInitDone = true;

          const result = await tblUser(strPageInfo, appState, "aaiotAppUserAll", {
            objectCondition: {
              // bigSystemID: bigSystemID,
              //intUserRoleFK: objUserRole.Student,
              ...(strTrainingType === appVariables.objTrainingToSupport?.fullstack?.key
                ? { blnIsStudent: true }
                : strTrainingType === appVariables.objTrainingToSupport?.researcher?.key
                ? { blnIsResearcher: true }
                :strTrainingType === appVariables.objTrainingToSupport?.volunteer?.key
                ?{ blnIsVolunteer: true }
                :strTrainingType === appVariables.objTrainingToSupport?.mdeas?.key
                ?{ blnIsMDEAS: true }
                : { blnIsStartUp: true }),
            },
            arrAttributes: objTableAttributes.tblUser.full.filter(
              (field) =>
                field !== "strUserPassword" &&
                field !== "txtLoginKeys" &&
                field !== "txtLoginIP" &&
                field !== "txtPushSubscription" &&
                field !== "intNumLoginAttempt" &&
                field !== "blnIsAccountLocked" &&
                field !== "dtmAccountLocked" &&
                field !== "strLastLoginUserAgent" &&
                field !== "strLastLoginIPAddress" &&
                field !== "txtChangePasswordInfo" &&
                field !== "txtLoginHistoryInfo"
            ),
          });

          
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.lstUser = Array.isArray(result?.response) ? (result?.response).reverse() : [];

          for (let i = 0; i < state?.lstUser?.length; i++) {
            const objUserInfo = state?.lstUser[i];

            if (String(appVariables?.objUserStatus?.Applicant) === String(objUserInfo?.[strUserStatusKey])) {
              ++state.objStatistic[appVariables?.objUserStatus?.Applicant];
            }
            if (String(appVariables?.objUserStatus?.Approved) === String(objUserInfo?.[strUserStatusKey])) {
              ++state.objStatistic[appVariables?.objUserStatus?.Approved];
            }
            if (String(appVariables?.objUserStatus?.Rejected) === String(objUserInfo?.[strUserStatusKey])) {
              ++state.objStatistic[appVariables?.objUserStatus?.Rejected];
            }
          }

          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstCountryOption = [];
          let objCountryOptionLang = {};

          let lstDegreeOption = [];
          let objDegreeOptionLang = {};

          let lstEmploymentOption = [];
          let objEmploymentOptionLang = {};

          let lstMajorOption = [];
          let objMajorOptionLang = {};

          let lstAcademicYearOption = [];
          let objAcademicYearOptionLang = {};

          let lstGPAOption = [];
          let objGPAOptionLang = {};

          let lstUniversityOption = [];
          let objUniversityOptionLang = {};

          let lstGenderOption = [];
          let objGenderOptionLang = {};

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.id });
              objCountryOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.id });
              objDegreeOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstEmploymentOption.push({ key: value?.id, value: value?.id });
              objEmploymentOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.id });
              objMajorOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstAcademicYearOption.push({ key: value?.id, value: value?.id });
              objAcademicYearOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gpa || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGPAOption.push({ key: value?.id, value: value?.id });
              objGPAOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.id });
              objGenderOptionLang[value?.id] = value?.value;
            }
          });

          fields.bigMajorID.setOptions(lstMajorOption, objMajorOptionLang);
          fields.bigDegreeID.setOptions(lstDegreeOption, objDegreeOptionLang);
          fields.bigGender.setOptions(lstGenderOption, objGenderOptionLang);
          fields.bigEmploymentID.setOptions(lstEmploymentOption, objEmploymentOptionLang);
          fields.bigCountryOfCitizenID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.bigCountryOfResidenceID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
          fields.bigAcademicYearID.setOptions(lstAcademicYearOption, objAcademicYearOptionLang);
          fields.intGPA.setOptions(lstGPAOption, objGPAOptionLang);

          let lstCountryOptionSort = [...lstCountryOption];
          let lstDegreeOptionSort = [...lstDegreeOption];
          let lstEmploymentOptionSort = [...lstEmploymentOption];
          let lstMajorOptionSort = [...lstMajorOption];
          let lstAcademicYearOptionSort = [...lstAcademicYearOption];
          let lstGPAOptionSort = [...lstGPAOption];
          let lstUniversityOptionSort = [...lstUniversityOption];

          lstCountryOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstDegreeOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstEmploymentOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstMajorOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstAcademicYearOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstGPAOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstUniversityOptionSort.unshift({ key: "all", value: allLang?.[lang] });

          fldSort.bigCountryOfCitizenID.setOptions(lstCountryOptionSort, objCountryOptionLang);
          fldSort.bigCountryOfResidenceID.setOptions(lstCountryOptionSort, objCountryOptionLang);
          fldSort.bigDegreeID.setOptions(lstDegreeOptionSort, objDegreeOptionLang);
          fldSort.bigEmploymentID.setOptions(lstEmploymentOptionSort, objEmploymentOptionLang);
          fldSort.bigMajorID.setOptions(lstMajorOptionSort, objMajorOptionLang);
          fldSort.bigAcademicYearID.setOptions(lstAcademicYearOptionSort, objAcademicYearOptionLang);
          fldSort.intGPA.setOptions(lstGPAOptionSort, objGPAOptionLang);
          fldSort.strUniversityName.setOptions(lstUniversityOptionSort, objUniversityOptionLang);
          fldSort.lstUserRole.setOptions(
            [
              { key: "all", value: allLang?.[lang] },
              { key: "student", value: "student" },
              { key: "entrepreneur", value: "entrepreneur" },
              { key: "manager", value: "manager" },
            ],
            {
              student: { eng: "Student", arb: "طالب" },
              entrepreneur: { eng: "Entrepreneur", arb: "ريادي" },
              manager: { eng: "Manager", arb: "مدير" },
            }
          );

          fldSort.bigCountryOfCitizenID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigCountryOfResidenceID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigDegreeID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigEmploymentID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigMajorID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigAcademicYearID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.intGPA.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.strUniversityName.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.lstUserRole.setValue({ key: "all", value: allLang?.[lang] });

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    onChangeCountry:
      ({ fldSort }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [{ key: "all", value: "all" }];
        let objUniversityOptionLang = { all: { eng: "All", value: "كل المتدربين" } };

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.btn.otherBtn;

        fldSort.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },
    resetFilterFields:
      ({ state, setState, fldSort }) =>
      (event) => {
        fldSort.bigCountryOfCitizenID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigCountryOfResidenceID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigDegreeID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigEmploymentID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigMajorID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigAcademicYearID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.intGPA.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.strUniversityName.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.lstUserRole.setValue({ key: "all", value: allLang?.[lang] });

        state.blnOpenSortModal = false;
        setState({ ...state });
      },
    filterUser:
      ({ fldSort, state }) =>
      (objUserInfo) => {
        const intGPA = fldSort?.intGPA?.getValue()?.key;
        const bigDegreeID = fldSort?.bigDegreeID?.getValue()?.key;
        const bigMajorID = fldSort?.bigMajorID?.getValue()?.key;
        const bigEmploymentID = fldSort?.bigEmploymentID?.getValue()?.key;
        const bigCountryOfCitizenID = fldSort?.bigCountryOfCitizenID?.getValue()?.key;
        const bigCountryOfResidenceID = fldSort?.bigCountryOfResidenceID?.getValue()?.key;
        const strUniversityName = fldSort?.strUniversityName?.getValue()?.key;
        const bigAcademicYearID = fldSort?.bigAcademicYearID?.getValue()?.key;
        const intUserRoleFK = fldSort.lstUserRole?.getValue()?.key;
        const strFullName = fldSort?.strFullNameSearch?.getValue();

        if (state?.bigCurrentTab) {
          if (
            String(state?.bigCurrentTab) !== String(objUserInfo?.[strUserStatusKey]) &&
            !(String(state?.bigCurrentTab) === String(appVariables?.objUserStatus?.Applicant) && !objUserInfo?.[strUserStatusKey])
          ) {
            return null;
          }
        }

        if (intUserRoleFK !== "all") {
          if (String(intUserRoleFK) === "student" && !objUserInfo?.blnIsStudent) {
            return null;
          }
          if (String(intUserRoleFK) === "entrepreneur" && !objUserInfo?.blnIsStartUp) {
            return null;
          }
          if (String(intUserRoleFK) === "manager" && !objUserInfo?.blnIsManager) {
            return null;
          }
        }

        if (bigAcademicYearID !== "all") {
          if (String(bigAcademicYearID) !== String(objUserInfo?.jsnUserInfo?.bigAcademicYearID)) {
            return null;
          }
        }

        if (intGPA !== "all") {
          if (String(intGPA) !== String(objUserInfo?.jsnUserInfo?.bigGpaID)) {
            return null;
          }
        }
        if (bigDegreeID !== "all") {
          if (String(bigDegreeID) !== String(objUserInfo?.jsnUserInfo?.bigDegreeID)) {
            return null;
          }
        }
        if (bigMajorID !== "all") {
          if (String(bigMajorID) !== String(objUserInfo?.jsnUserInfo?.strMajor)) {
            return null;
          }
        }
        if (bigEmploymentID !== "all") {
          if (String(bigEmploymentID) !== String(objUserInfo?.jsnUserInfo?.bigEmploymentID)) {
            return null;
          }
        }
        if (bigCountryOfCitizenID !== "all") {
          if (String(bigCountryOfCitizenID) !== String(objUserInfo?.jsnUserInfo?.bigCountryOfCitizenID)) {
            return null;
          }
        }
        if (bigCountryOfResidenceID !== "all") {
          if (String(bigCountryOfResidenceID) !== String(objUserInfo?.jsnUserInfo?.bigCountryOfResidenceID)) {
            return null;
          }
        }
        if (strUniversityName !== "all") {
          if (String(strUniversityName) !== String(objUserInfo?.jsnUserInfo?.strUniversityName)) {
            return null;
          }
        }

        if (strFullName && String(strFullName)?.length) {
          if (
            !String(objUserInfo?.jsnUserInfo?.strFullName?.arb).toLocaleLowerCase().includes(String(strFullName).toLocaleLowerCase()) &&
            !String(objUserInfo?.jsnUserInfo?.strFullName?.eng).toLocaleLowerCase().includes(String(strFullName).toLocaleLowerCase())
          ) {
            return null;
          }
        }

        return true;
      },

    openUserDetail:
      ({ state, setState, fields, strTrainingType }) =>
      (objUserDetail) =>
      async (event) => {
        try {
          if (!objUserDetail?.jsnUserInfo?.blnIsSeen?.[strTrainingType] && objUserDetail?.bigUserID && appState?.userInfo?.jsnUserInfo?.intUserRoleFK !== appVariables.objUserRole?.RootAdmin) {
            if (typeof objUserDetail.jsnUserInfo.blnIsSeen !== "object") {
              objUserDetail.jsnUserInfo.blnIsSeen = {};
            }
            objUserDetail.jsnUserInfo.blnIsSeen[strTrainingType] = true;
            await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
              objectCondition: {
                bigUserID: objUserDetail?.bigUserID,
              },
              objectInput: {
                jsnUserInfo: objUserDetail.jsnUserInfo,
              },
            });
          }
          // *** tbd ***
          // fields.strUserEmail.setValue(objUserDetail?.strUserEmail);
          // fields.strUserPhone.setValue(objUserDetail?.strUserPhone);
          // fields.strFullName.setValue(
          //   typeof objUserDetail?.jsnUserInfo?.strFullName === "object" ? objUserDetail?.jsnUserInfo?.strFullName?.eng : objUserDetail?.jsnUserInfo?.strFullName
          // );
          // fields.dtmDob.setValue(objUserDetail?.jsnUserInfo?.dtmDOB);
          // fields.strGovID.setValue(objUserDetail?.jsnUserInfo?.strGovID);
          // fields.bigMajorID.setValue(objUserDetail?.jsnUserInfo?.strMajor);
          // fields.strAddress.setValue(objUserDetail?.jsnUserInfo?.strAddress);
          // fields.bigDegreeID.setValue(objUserDetail?.jsnUserInfo?.bigDegreeID);
          // fields.bigGender.setValue(objUserDetail?.jsnUserInfo?.bigGender);
          // fields.dtmGradDate.setValue(objUserDetail?.jsnUserInfo?.dtmGradDate);
          // fields.bigEmploymentID.setValue(objUserDetail?.jsnUserInfo?.bigEmploymentID);
          // fields.intGPA.setValue(objUserDetail?.jsnUserInfo?.bigGpaID);
          // fields.bigCountryOfCitizenID.setValue(objUserDetail?.jsnUserInfo?.bigCountryOfCitizenID);
          // fields.bigCountryOfResidenceID.setValue(objUserDetail?.jsnUserInfo?.bigCountryOfResidenceID);
          // handler.onChangeCountryForUser({ fields })({ target: { id: objUserDetail?.jsnUserInfo?.bigCountryOfResidenceID } });
          // fields.strUniversityName.setValue(objUserDetail?.jsnUserInfo?.strUniversityName);
          // fields.bigAcademicYearID.setValue(objUserDetail?.jsnUserInfo?.bigAcademicYearID);
          // fields.strWorkPlace.setValue(objUserDetail?.jsnUserInfo?.strWorkPlace);
          // fields.strPositionTitle.setValue(objUserDetail?.jsnUserInfo?.strPositionTitle);

          state.blnOpenActionModal = true;
          state.objUserDetail = objUserDetail;
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "openUserDetail-Error");
        }
      },
    openIntroductoryVideo:
      ({ state, setState }) =>
      () => {
        setState({ ...state, blnOpenIntroductoryVideo: !state?.blnOpenIntroductoryVideo });
      },
    onChangeCountryForUser:
      ({ fields }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [];
        let objUniversityOptionLang = {};

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.btn.otherBtn;

        fields.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },
    updateUserInDB:
      ({ state, setState, isLoading, setIsLoading, fields }) =>
      (bigUserStatusID = null) =>
      async (event) => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          // ***tbd***
          // state.objUserDetail.jsnUserInfo.strFullName = { eng: fields.strFullName.getValue(), arb: fields.strFullName.getValue() };
          // state.objUserDetail.jsnUserInfo.dtmDOB = fields.dtmDob.getValue();
          // state.objUserDetail.jsnUserInfo.strGovID = fields.strGovID.getValue();
          // state.objUserDetail.jsnUserInfo.strMajor = fields.bigMajorID.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.strAddress = fields.strAddress.getValue();
          // state.objUserDetail.jsnUserInfo.bigDegreeID = fields.bigDegreeID.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.bigGender = fields.bigGender.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.dtmGradDate = fields.dtmGradDate.getValue();
          // state.objUserDetail.jsnUserInfo.bigEmploymentID = fields.bigEmploymentID.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.bigCountryOfCitizenID = fields.bigCountryOfCitizenID.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.bigCountryOfResidenceID = fields.bigCountryOfResidenceID.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.strUniversityName = fields.strUniversityName.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.bigAcademicYearID = fields.bigAcademicYearID.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.bigGpaID = fields.intGPA.getValue()?.key;
          // state.objUserDetail.jsnUserInfo.strWorkPlace = fields.strWorkPlace.getValue();
          // state.objUserDetail.jsnUserInfo.strPositionTitle = fields.strPositionTitle.getValue();

          if (bigUserStatusID && state.objUserDetail?.[strUserStatusKey] !== bigUserStatusID) {
            --state.objStatistic[state.objUserDetail?.[strUserStatusKey]];
            ++state.objStatistic[bigUserStatusID];
            state.objUserDetail[strUserStatusKey] = bigUserStatusID;
          }
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectCondition: {
              bigUserID: state?.objUserDetail?.bigUserID,
            },

            objectInput: {
              jsnUserInfo: {
                ...state.objUserDetail.jsnUserInfo,
                objUserStatusKey: {
                  [strTrainingType]: bigUserStatusID,
                },
              },
              bigUserStatusID: bigUserStatusID,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.blnOpenUserDetailModal = false;
          setIsLoading(false);
          setState({ ...state });
          if (bigUserStatusID) {
            Alert.viewAlert(dictionary?.components.mnageRequestJoin?.traineeStatusUpdateSuccess?.[lang], "success");
          } else {
            Alert.viewAlert(dictionary?.components.mnageRequestJoin?.traineeInfoUpdateSuccess?.[lang], "success");
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "updateUserInDB-Error");
        }
      },
    confirmUpdateUserInDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType }) =>
      (bigUserStatusID = null, isConfirmation = false) =>
      () => {
        if (bigUserStatusID && isConfirmation) {
          Confirmation.viewConfirmation(
            dictionary?.components?.managePartner?.confirmUpdateTrainee?.[lang],
            () => setState({ ...state, blnOpenActionModal: true, blnOpenUserDetailModal: false }),
            handler.updateUserInDB({ state, setState, isLoading, setIsLoading, fields })(bigUserStatusID),
            dictionary?.shared?.confirmationMsg?.procedure?.[lang],
            dictionary?.shared?.confirmationMsg?.noProcedure?.[lang]
          );
        } else {
          state.blnOpenActionModal = false;
          handler.updateUserInDB({ state, setState, isLoading, setIsLoading, fields })(bigUserStatusID)();
        }
      },
  };

  return handler;
}
