export const appLanguage = {
  arb: { blnActive: true, blnIsMandatory: false, label: "العربية" },
  eng: { blnActive: true, blnIsMandatory: true, label: "English" },
};

export const appLangDirection = {
  arb: "rtl",
  eng: "ltr",
};

export const appLangOrientation = {
  arb: "Right",
  eng: "Left",
};

export const appLangType = {
  arb: "arb",
  eng: "eng",
};

export const dictionary = {
  formapi: {
    strFullName: {
      label: { eng: "Full Name", arb: "الإسم الكامل" },
      validationsMsg: {
        required: { eng: "Please enter your name", arb: "الرجاء ادخال اسمك" },
      },
    },
    strUserEmail: {
      label: { eng: "Email", arb: "البريد الإلكتروني" },
      validationsMsg: {
        required: { eng: "Please enter your email address", arb: "الرجاء ادخال بريدك الإلكتروني" },
        email: { eng: "Please enter the email address correctly", arb: "الرجا اخال البريد الإلكتروني بطريقة صحيح" },
      },
    },
    bigWorkSector: {
      label: { eng: "Work Sector", arb: "قطاع العمل" },
      validationsMsg: {
        required: { eng: "Please select the type of sector", arb: "الرجاء اختيار نوع القطاع" },
      },
    },
    bigWorkSector2: {
      label: { eng: "Select a category", arb: "إختر الفئة" },
      validationsMsg: {
        required: { eng: "Please select the category", arb: "الرجاء اختيار الفئة" },
      },
    },
    lstTargeted: {
      label: { eng: "Audience", arb: "الفئة" },
      validationsMsg: {
        required: { eng: "Please select the category you belong to", arb: "الرجاء اختيار الفئة التي تنتمي إليها" },
      },
    },
    bigWorkGovSectorType: {
      label: { eng: "Type of the public sector", arb: "نوع القطاع الحكومي" },
      validationsMsg: {
        required: { eng: "Please select type of the public sector", arb: "الرجاء اختيار نوع القطاع الحكومي " },
      },
    },
    bigGovPlaceName: {
      label: { eng: "The name of the government agency", arb: "إسم الجهة الحكومية" },
      validationsMsg: {
        required: { eng: "Please select type of the name of the government agency", arb: "الرجاء اختيار إسم الجهة الحكومية " },
      },
    },
    strPlaceName: {
      label: { eng: "Name of the organization", arb: "اسم جهة العمل" },
      validationsMsg: {
        required: { eng: "Please enter your place of work", arb: "الرجاء ادخال مكان عملك" },
      },
    },
    lstSubject: {
      validationsMsg: {
        required: { eng: "Please select the workshops and seminars you would like to attend", arb: "الرجاء اختيار الورشات والندوات التي تود حضورها" },
      },
    },
    strUserPassword: {
      label: { eng: "Password", arb: "كلمة المرور" },
      validationsMsg: {
        required: { eng: "Please enter the new password", arb: "الرجاء ادخال كلمة المرور الجديد" },
        minLength: { eng: "Please enter a minimum of 4 fields", arb: "الرجاء إدخال 4 خانات كحد أدنى" },
      },
    },
    strConfirmPassword: {
      label: { eng: "Conform Password", arb: "تأكيد كلمة المرور" },
      validationsMsg: {
        required: { eng: "Please enter the password confirmation", arb: "الرجاء ادخال تأكيد كلمة المرور" },
        minLength: { eng: "Please enter a minimum of 4 fields", arb: "الرجاء إدخال 4 خانات كحد أدنى" },
        equal: { eng: "Passwords Don't match", arb: "كلمة المرور غير متطابقة" },
      },
    },
    strGPA: {
      label: { eng: "Cumulative GPA", arb: "المعدل التراكمي" },
      validationsMsg: {
        required: { eng: "Please enter your GPA", arb: "الرجاء ادخال معدلك" },
        maxNumber: { eng: "Please adhere to the maximum allowable grade", arb: "الرجاء الالتزام بالحد الأقصى للمعدل" },
        onlyNumbers: { eng: "Please enter numbers only", arb: "الرجاء إدخال أرقام فقط" },
      },
    },
    strGPAof: {
      //label: { eng: "Cumulative GPA of", arb: "المعدل التراكمي من" },
      validationsMsg: {
        required: { eng: "Please select", arb: "الرجاء الاختيار" },
      },
      option: { 4: { eng: "4/", arb: "/4" }, 100: { eng: "/100", arb: "/100" }, A: { eng: "/A", arb: "/A" } },
    },
    strYearGraduation: {
      label: { eng: "Year of graduation", arb: "سنة التخرج" },
      validationsMsg: {
        required: { eng: "Please enter year of graduation", arb: "الرجاء ادخال سنة تخرجك" },
        minLength: { eng: "Please enter a minimum of 4 fields", arb: "الرجاء إدخال 4 خانات كحد أدنى" },
        maxLength: { eng: "Please enter a maximum of 4 fields", arb: "الرجاء إدخال 4 خانات كحد أقصى" },

      },
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#region account fields
    bigMajorID: {
      label: { eng: "Major", arb: "التخصص" },
      validationsMsg: {
        required: { eng: "Please select a major", arb: "الرجاء اختيار تخصصك" },
      },
    },
    bigDegreeID: {
      label: { eng: "Degree", arb: "الدرجة العلمية" },
      validationsMsg: {
        required: { eng: "Please select a degree", arb: "الرجاء اختيار درجتك العلمية" },
      },
    },
    bigGender: {
      label: { eng: "Gender", arb: "الجنس" },
      validationsMsg: {
        required: { eng: "Please select your gender", arb: "الرجاء اختيار جنسك" },
      },
    },
    bigEmploymentID: {
      label: { eng: "Employment Status", arb: "الحالة الوظيفية" },
      validationsMsg: {
        required: { eng: "Please select employment Status", arb: "الرجاء اختيار حالتك الوظيفية" },
      },
    },
    bigCountryOfCitizenID: {
      label: { eng: "Citizen", arb: "الجنسية" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigCountryOfResidenceID: {
      label: { eng: "Country Of Residence", arb: "بلد الإقامة" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigUniversityName: {
      label: { eng: "University", arb: "الجامعة" },
      validationsMsg: {
        required: { eng: "Please select a university", arb: "الرجاء اختيار الجامعة" },
      },
    },
    strUniversityName: {
      label: { eng: "University Name", arb: "اسم الجامعة" },
      validationsMsg: {
        required: { eng: "Please enter university name", arb: "الرجاء ادخال اسم الجامعة" },
      },
    },
    bigAcademicYearID: {
      label: { eng: "Academic Year", arb: "السنة الأكاديمية" },
      validationsMsg: {
        required: { eng: "Please select the academic year", arb: "الرجاء اختيار السنة الأكاديمية" },
      },
    },
    lstRanking: {
      label: { eng: "Your ranking in the department", arb: "ترتيبك على القسم" },
      options: {
        first: { eng: "The First", arb: "الأول" },
        second: { eng: "The Second", arb: "الثاني" },
        third: { eng: "The Third", arb: "الثالث" },
      },
      validationsMsg: {
        required: { eng: "Please select your ranking in the department", arb: "الرجاء اختيار ترتيبك على القسم" },
      },
    },
    bigGpaID: {
      label: { eng: "GPA", arb: "المعدل التراكمي" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strUserPhone: {
      label: { eng: "Phon Number", arb: "رقم الهاتف" },
      validationsMsg: {
        required: { eng: "Please enter your phone number", arb: "الرجاء ادخال رقم هاتفك" },
        minLength: { eng: "The number of digits entered is less than the permissible limit of 5 digits", arb: "عدد الخانات التي تم إدخالها أقل من الحد المسموح به هو  5 خانات" },
        maxLength: {
          eng: "The number of digits entered is greater than the permissible limit of 10 digits",
          arb: "عدد الخانات التي تم إدخالها أكبر من الحد المسموح به هو  10 خانات",
        },
        onlyNumbers: { eng: "Please enter numbers only", arb: "الرجاء إدخال أرقام فقط" },
      },
    },
    strSponserName: {
      label: { eng: "Name of the Support Officer", arb: "إسم مسؤول الدعم" },
      validationsMsg: {
        required: { eng: "Please enter the support officer", arb: "الرجاء ادخال إسم مسؤول الدعم " },
      },
    },
    blnAddNote: { label: { eng: "Add a Note", arb: "إضافة ملاحظة" } },
    strNote: { label: { eng: "Note", arb: "ملاحظة" } },
    strPlaceName2: {
      label: { eng: "Supporter Name", arb: "إسم الجهة الداعمة" },
      validationsMsg: {
        required: { eng: "Please enter the supporter name", arb: "الرجاء ادخال إسم الجهة الداعمة " },
      },
    },

    strWorkPlace: {
      label: { eng: "The workplace name", arb: "مكان العمل" },
      validationsMsg: {
        required: { eng: "Please enter the supporter name", arb: "الرجاء ادخال إسم الجهة الداعمة " },
      },
    },
    strPositionTitle: {
      label: { eng: "Position title", arb: "المسمى الوظيفي" },
      validationsMsg: {
        required: { eng: "Please enter your position title", arb: "الرجاء ادخال المسمى الوظيفي " },
      },
    },
    strURL: {
      label: { eng: "Website Link", arb: "رابط الموقع" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    blnExperience: {
      label: { eng: "I have experience in my field", arb: "لدي خبرة في مجال تخصصي" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    intExperienceYear: {
      label: { eng: "Number of years of experience", arb: "عدد سنوات الخبرة" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    //#endregion
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    strSponsorTrainingType: {
      placeholder: { eng: "Select a support category", arb: "اختيار فئة الدعم" },
      validationsMsg: {
        required: { eng: "Please select a support category", arb: "الرجاء اختيار فئة الدعم" },
      },
    },
    blnIsFirstSections: {
      label: { eng: "From the first sections", arb: "من أوائل الأقسام" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },

    blnVewLogo: { label: { eng: "Add the sponsor's logo on the main page of the association", arb: "إضافة شعار الجهة الداعمة في الصفحة الرئيسية للجمعية" } },
    strOldUserPassword: {
      label: { eng: "Old Password", arb: "كلمة المرور القديمة" },
      validationsMsg: {
        minLength: { eng: "The number of digits entered is less than the permissible limit of 4 digits", arb: "عدد الخانات التي تم إدخالها أقل من الحد المسموح به هو  4 خانات" },
      },
    },
    strRestEmail: { label: { eng: "New Email", arb: "البريد الإلكتروني الجديد" } },
    strRestPassword: {
      label: { eng: "New Password", arb: "كلمة المرور الجديدة" },
      validationsMsg: {
        minLength: { eng: "The number of digits entered is less than the permissible limit of 4 digits", arb: "عدد الخانات التي تم إدخالها أقل من الحد المسموح به هو   4 خانات" },
      },
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#region registration forum
    strBadgeID: {
      label: { eng: "Verification Code", arb: "رمز التحقق" },
      validationsMsg: {
        minLength: { eng: "The number of digits entered is less than the permissible limit of 6 digits", arb: "عدد الخانات التي تم إدخالها أقل من الحد المسموح به هو 6 خانات" },
      },
    },
    //#endregion

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#region manage registration forum
    strForumName: { label: { eng: "Conference title", arb: "عنوان المؤتمر" } },
    dtmForumDate: { label: { eng: "Date of The Conference", arb: "تاريخ الانعقاد" } },
    strForumLocation: { label: { eng: "Venue", arb: "مكان الانعقاد" } },
    dtmForumExpDateTime: { label: { eng: "Date and time of the event's conclusion", arb: "تاريخ و وقت انتهاء الفاعلية" } },
    strForumExpURL: { label: { eng: "Link to the page after the event", arb: "رابط الصفحة بعد انتهاء الفعالية" } },
    blnStrictRegForum: { options: { true: { eng: "Verifying applicants after registration", arb: "التأكد من المتقدمين بعد التسجيل" } } },
    blnHaveSubForum: { options: { true: { eng: "Sub-forum", arb: "ندوات فرعية" } } },
    strSubForumName: { label: { eng: "Sub-forum title", arb: "عنوان المؤتمر" } },
    dtmSubForumData: { label: { eng: "Date of The Sub-forum", arb: "تاريخ الانعقاد" } },
    strSubForumLocation: { label: { eng: "Venue", arb: "مكان الانعقاد" } },
    //#endregion

    strUrlCode: {
      label: {
        eng: "URL Code",
        arb: "كود الرابط",
      },
    },
    strTitle: {
      label: {
        eng: "Title",
        arb: "العنوان",
      },
    },
    lstReg: {
      label: {
        eng: "select who can register on this page",
        arb: "حدد الفئات المؤهلة للتسجيل في هذه الصفحة",
      },
    },
    strSubtitle: {
      label: {
        eng: "Subtitle",
        arb: "العنوان الفرعي",
      },
    },
    strDscrp: {
      label: {
        eng: "Details",
        arb: "تفاصيل",
      },
    },
    lstPoints: {
      label: {
        eng: "Points",
        arb: "نقاط",
      },
    },
    isViewMediaBtn: {
      label: {
        eng: "Enable Video View Button",
        arb: "تفعيل زر مشاهدة الفيديو",
      },
    },
    mediaBtn: {
      label: {
        eng: "Vedio Button label",
        arb: "تسمية زر الفيديو",
      },
    },
    isForumBtn: {
      label: {
        eng: "Enable the forum registration button",
        arb: "تفعيل زر التسجيل في المنتدى",
      },
    },
    strForumBtn: {
      label: {
        eng: "Forum Button label",
        arb: "تسمية زر المنتدى",
      },
    },
    strForumKey: {
      label: {
        eng: "Forum Key Code",
        arb: "كود مفتاح المنتدى",
      },
    },
    blnIsViwBefor: {
      label: {
        eng: "Display the section before the video",
        arb: "عرض القسم الذي قبل يعرض الفيديو",
      },
    },
    blnIsViwAfter: {
      label: {
        eng: "Display the section after the video",
        arb: "عرض القسم الذي بعد الفيديو",
      },
    },

    blnAppsNeedDev: {
      label: {
        eng: "Do you think that some applications or electronic services in the sector you work in need development",
        arb: "هل تعتقد أن بعض التطبيقات أو الخدمات الالكترونية في القطاع الذي تعمل معه تحتاج الى تطوير",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    strExplainProposal: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },
    blnGraduationProject: {
      label: {
        eng: "Would you like your graduation project for this course to focus on improving electronic services in the government sector of your choice",
        arb: "هل ترغب أن يكون مشروع تخرجك في هذه الدورة يستهدف تحسين الخدمات الالكترونية في القطاع الحكومي الذي اخترته",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    blnHaveGoveIdea: {
      label: {
        eng: "Do you have an idea or a set of ideas for an application or several applications that can be implemented for the government sector you have chosen",
        arb: "هل لديك تصور عن فكره او مجموعة أفكار لتطبيق او عدة تطبيقات يمكن تنفيذها للقطاع الحكومي الذي اخترته",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    blnlikeWorkinGivSec: {
      label: {
        eng: "Do you have the desire to work in the government sector",
        arb: "هل لديك الرغبة للعمل في القطاع الحكومي",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },

    blnExpJs: {
      label: {
        eng: "Do you have experience in JavaScript",
        arb: "هل لديك خبرة في جافاسكربت",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    intYearExpJs: {
      placeholder: {
        arb: "عدد السنوات",
        eng: "Number of years",
      },
      validationsMsg: {
        required: { eng: "Please enter your years of experience", arb: "الرجاء ادخال عدد سنوات خبرتك" },
      },
    },
    intNumJsProject: {
      placeholder: {
        arb: "عدد المشاريع",
        eng: "Number of projects",
      },
    },
    strExpJsProject: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },
    blnExFe: {
      label: {
        eng: "Do you have experience in designing client applications",
        arb: "هل لديك خبرة في تصميم تطبيقات العميل",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    intYearExpFn: {
      placeholder: {
        arb: "عدد السنوات",
        eng: "Number of years",
      },
    },
    strExpFeProject: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },
    blnExpNode: {
      label: {
        eng: "Do you have experience with Node.js, Deno, or Bun",
        arb: "هل لديك خبرة في Node.js أو Deno أو Bun",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    intYearExpNode: {
      placeholder: {
        arb: "عدد السنوات",
        eng: "Number of years",
      },
    },
    intNumNodeProject: {
      placeholder: {
        arb: "عدد المشاريع",
        eng: "Number of projects",
      },
    },
    strExpNodeProject: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },
    blnExAi: {
      label: {
        eng: "Do you have experience in designing artificial intelligence applications",
        arb: "هل لديك خبرة في تصميم تطبيقات الذكاء الاصطناعي",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    intYearExpAi: {
      placeholder: {
        arb: "عدد السنوات",
        eng: "Number of years",
      },
    },
    strExpAiProject: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },
    blnExCybS: {
      label: {
        eng: "Do you have experience in designing cybersecurity applications and secure programming",
        arb: "هل لديك خبرة في الأمن السيبراني والبرمجة الآمنة",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    intYearExpCybS: {
      placeholder: {
        arb: "عدد السنوات",
        eng: "Number of years",
      },
    },
    strExpCybSProject: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },
    blnExMobile: {
      label: {
        eng: "Do you have experience in designing mobile applications",
        arb: "هل لديك خبرة في تصميم تطبيقات الذكية",
      },
      options: {
        true: {
          eng: "Yes",
          arb: "نعم",
        },
        false: {
          eng: "No",
          arb: "لا",
        },
      },
    },
    intYearExpMobile: {
      placeholder: {
        arb: "عدد السنوات",
        eng: "Number of years",
      },
    },
    strExpMobileProject: {
      placeholder: {
        arb: "شرح مختصر",
        eng: "Brief explanation",
      },
    },

    strQuestion: {
      label: { eng: "Question", arb: "السؤال" },
    },
    strQuestionGrp: {
      label: { eng: "Question set", arb: "مجموعة السؤال" },
    },
    lstQuestionType: {
      label: { eng: "Question Type", arb: "نوع السؤال" },
      options: {
        101: { eng: "Yes or No", arb: "نعم أو لا" },
        102: { eng: "Multiple choice", arb: "متعدد الخيارات" },
        103: { eng: "Writing", arb: "كتابة" },
      },
    },
    strOption: {
      label: { eng: "Option", arb: "الخيار" },
    },
    questionNo: {
      label: {
        eng: "The question number that determines whether this question will appear or not",
        arb: "رقم السؤال الذي يحدد ما إذا كان هذا السؤال سيظهر أم لا",
      },
    },
    required: {
      label: {
        eng: "Required",
        arb: "الحقل مطلوب",
      },
    },

    strSrearh: {
      placeholder: {
        eng: "Srearh",
        arb: "بحث",
      },
    },
    strQCaptionB: {
      label: { eng: "Explanation before the question", arb: "شرح قبل السؤال" },
    },
    strQCaptionA: {
      label: { eng: "Explanation after the question", arb: "شرح بعد السؤال" },
    },
  },
  formValidationsMsg: {
    required: { eng: "Required!", arb: "الحقل مطلوب" },
    email: { eng: "Invalid email address", arb: "البريد الإلكتروني غير صحيح" },
    onlyNumbers: { eng: "Please enter numbers only", arb: "الرجاء إدخال أرقام فقط" },
    url: { eng: "Please enter the correct link", arb: "الرجاء إدخال الرابط الصحيح" },
  },
  components: {
    public: {
      appName: {
        eng: "The association of Artificial Intelligence for Things",
        arb: " جمعية الذكاء الإصطناعي للأشياء",
      },
      landingPage: {
        hero: {
          body: {
            eng: "An association concerned with the software and technology sector in its various sectors, to be a real building block for supporting the national economic base and contributing to building a creative generation",
            arb: "جمعية تُعنى بقطاع البرمجيات والتكنولوجيا بمختلف قطاعاتها لتكون لبنة حقيقية لدعم القاعدة الاقتصادية الوطنية والمساهمة ببناء جيل مبدع",
          },
        },
      },
      registration: {
        title: { eng: "Registration", arb: "تسجيل" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        detailsInfo: {
          eng: "Seminars and Workshops",
          arb: "الندوات وورش العمل",
        },
        invite: {
          eng: "Welcome to the first Arabic (Kuwaiti) technology platform specializing in providing effective software tools and management systems to help software engineers and programming companies develop secure applications with minimal effort and in the shortest time. We offer training for competencies on using the latest global technologies, support entrepreneurs in turning their innovative ideas into profitable applications, and enhance the capabilities of graduates in technical fields to improve their opportunities in the competitive job market",
          arb: "مرحباً بكم في أول منصة تقنية عربية (كويتية) متخصصة في توفير أدوات برمجية وأنظمة إدارية فعّالة لمساعدة مهندسي البرمجيات وشركات البرمجة على تطوير تطبيقات آمنة بأقل جهد وفي أقصر وقت، تدريب الكفاءات على إستخدام أحدث التقنيات العالمية، دعم رواد الأعمال في تحويل أفكارهم الريادية إلى تطبيقات ربحية، تعزيز قدرة خريجي التخصصات التقنية وتحسين فرصهم في سوق العمل التنافسي",
        },
        btn: { eng: "Registration", arb: "تسجيل" },
        alertThanke: {
          eng: "Thank you for registering for the Digital Empowerment and Artificial Intelligence Week. We look forward to enriching your experience and maximizing your benefit",
          arb: "نشكركم على تسجيلكم في جمعية الذكاء الاصطناعي للأشياء ونتطلع إلى إثراء تجربتكم وتحقيق استفادة قصوى",
        },
      },
      trainingRegistration: {
        title: { eng: "Registration for Free Training", arb: "التسجيل في التدريب المجاني" },
        subTitle: {
          eng: "Smart and Secure Software Engineering and How to Achieve Digital Empowerment in the State of Kuwait",
          arb: "هندسة البرمجيات الذكية والآمنة وكيفية تحقيق التمكين الرقمي في دولة الكويت",
        },
        details: {
          eng: `"This comprehensive training targets government sector employees and graduates or students from technical disciplines who wish to work with various government sectors as engineers or software developers. The training covers all the skills required to build different parts of applications using the latest globally available technologies. It also includes fundamentals of artificial intelligence and cybersecurity (secure programming). The training is designed using a variety of modern teaching methods to create a comprehensive and flexible learning experience that aligns with the schedules and circumstances of all trainees. It is structured to be fully remote, but a series of in-person meetings will be held. To ensure the same level of personal support throughout the rest of the training as in the in-person sessions, dedicated trainers will be assigned to follow up with the trainees. Additionally, periodic live meetings and office hours will be available at different times via Zoom to discuss advanced programming topics and provide full support to trainees according to their free time. The training also includes diverse tasks that simulate work environments in global programming companies, ensuring that all trainees are empowered to build large applications efficiently and effectively in the least amount of time and effort.`,
          arb: `يستهدف هذا التدريب المتكامل موظفي القطاع الحكومي وخريجي التخصصات التقنية او طلبة التخصصات التقنية الراغبين في العمل مع القطاعات الحكومية المختلفة كمهندسي او مطوري برمجيات. يغطي التدريب جميع المهارات المطلوبة لبناء أجزاء التطبيق المختلفة وباستخدام  أحدث التقنيات المتاحة عالمياً. كما يغطي التدريب أيضاً اساسيات الذكاء الإصطناعي والأمن السيبراني (البرمجة الاًمنة). تم تصميم هذا التدريب باستخدام مجموعة متنوعة من الأساليب التدريسية الحديثة، لخلق تجربة تعليمية شاملة ومرنة تتماشى مع أوقات وظروف جميع المتدربين. التدريب مصمم ليكون عن بُعد بالكامل ولكن سيتم عقد مجوعة من اللقاءات بشكل وجاهي، ولضمان توفير نفس المستوى من الدعم الشخصي لبقية التدريب كما في التدريب الوجاهي، سيتم تخصيص مدربين لمتابعة المتدربين. بالإضافة إلى ذلك، ستتوفر لقاءات دورية مباشرة وساعات مكتبية في أوقات مختلفة عبر تطبيق زوم، لمناقشة مواضيع متقدمة في البرمجة وتقديم الدعم الكامل للمتدربين بما يتماشى مع أوقات فراغهم. كما يتضمن التدريب مهام متنوعة تحاكي بيئات العمل في شركات البرمجة العالمية، لضمان تمكين جميع المتدربين من بناء تطبيقات ضخمة بكفاءة وفعالية وفي أقل وقت وجهد.`,
        },
        details2: {
          eng: `Previous programming experience is not required since the training in its initial stage covers all the necessary fundamentals`,
          arb: `لا يشترط امتلاك خبرة مسبقة في البرمجة لأن التدريب في مرحلته الأولى سيغطي جميع الأساسيات المطلوبة`,
        },
        internInfo: { eng: "Intern information", arb: "معلومات المتدرب" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        jobCategory: {
          eng: "Intern category",
          arb: "فئة المتدرب",
        },
        interviewQuestions: {
          eng: "General Questions",
          arb: "أسئلة عامة",
        },
        blnGraduationProject: {
          eng: "Would you like your graduation project for this course to focus on improving electronic services in the government sector where you work",
          arb: "هل ترغب أن يكون مشروع تخرجك في هذه الدورة يستهدف تحسين الخدمات الالكترونية في القطاع الحكومي الذي تعمل معه",
        },
        strExplainProposal: {
          arb: "يرجى تقديم شرح مختصر لفكرتك التي ترغب في العمل عليها كمشروع تخرج، أو اقتراحك لتحسين التطبيقات أو الخدمات التي تعتقد أنها بحاجة إلى تطوير",
          eng: "Please provide a brief explanation of your idea for your graduation project or your suggestion for improving applications or services that you believe need development",
        },
        strExplainIdea: {
          eng: "Please provide a brief explanation of the idea or project you are considering",
          arb: "يرجى  تقديم شرح مختصر عن الفكرة أو المشروع الذي تفكر به",
        },
        intYearExpJs: {
          eng: "Years of experience in JavaScript",
          arb: "عدد سنوات الخبرة في جافا سيكريت",
        },
        intNumJsProject: {
          eng: "The number of projects you have worked on using JavaScript",
          arb: "عدد المشاريع التي عملت عليها في جافاسكربت",
        },
        strExpJsProject: {
          eng: "A brief explanation of your experience in JavaScript",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في جافاسكربت",
        },
        intYearExpFn: {
          eng: "Years of experience in designing client applications",
          arb: "عدد سنوات الخبرة في تصميم تطبيقات العميل",
        },
        strExpFeProject: {
          eng: "A brief explanation of your experience in client application programming",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في برمجة تطبيقات العميل",
        },
        intYearExpNode: {
          eng: "Years of experience in Node.js, Deno, or Bun",
          arb: "عدد سنوات الخبرة في Node.js أو Deno أو Bun",
        },
        intNumNodeProject: {
          eng: "The number of projects you have worked on using Node.js, Deno, or Bun",
          arb: "عدد المشاريع التي عملت عليها في Node.js أو deno أو bun",
        },
        strExpNodeProject: {
          eng: "A brief explanation of your experience with Node.js, Deno, or Bun",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في Node.js أو deno أو bun",
        },
        intYearExpAi: {
          eng: "Years of experience in designing artificial intelligence applications",
          arb: "عدد سنوات الخبرة في تصميم تطبيقات الذكاء الاصطناعي",
        },
        strExpAiProject: {
          eng: "A brief explanation of your experience in artificial intelligence",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في الذكاء الاصطناعي ",
        },
        intYearExpCybS: {
          eng: "Years of experience in designing cybersecurity applications and secure programming applications",
          arb: "عدد سنوات الخبرة في الأمن السيبراني والبرمجة الآمنة",
        },
        strExpCybSProject: {
          eng: "A brief explanation of your experience in cybersecurity applications and secure programming",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في الأمن السيبراني والبرمجة الآمنة ",
        },
        intYearExpMobile: {
          eng: "Years of experience in designing mobile applications",
          arb: "عدد سنوات الخبرة في تصميم تطبيقات الذكية",
        },
        strExpMobileProject: {
          eng: "A brief explanation of your experience in mobile programming",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في البرمجة التطبيقات الذكية ",
        },
        uploadVideo: {
          arb: "يرجى تقديم فيديو تعريفي عنك يبدأ بتعريف مختصر لنفسك، ثم يبرز أعمالك دون الحاجة لإظهار وجهك، مع الاكتفاء بتسجيل صوتي يرافق عرض الأعمال",
          eng: "Please submit an introductory video that begins with a brief introduction of yourself, followed by a showcase of your work. There is no need to show your face; a voiceover accompanying the display of your work will suffice",
        },
        uploadTranscript: {
          eng: "Please attach your transcript to verify if you are among the top of your class, which will grant you priority for the training opportunity",
          arb: "الرجاء إرفاق كشف علاماتك للتحقق من كونك ضمن أوائل الدفعة، مما سيمنحك أولوية للحصول على فرصة التدريب",
        },

        video: { eng: "video", arb: "فيديو" },
      },
      researcherRegistration:{
        title: { eng: "Researcher Registration", arb: "تسجيل الباحثيين العلميين" },
        subTitle: {
          eng: "Empowering Researchers Technologically",
          arb: "تمكين الباحثين تقنيًا",
        },
        details: {
          eng: `"This comprehensive training targets government sector employees and graduates or students from technical disciplines who wish to work with various government sectors as engineers or software developers. The training covers all the skills required to build different parts of applications using the latest globally available technologies. It also includes fundamentals of artificial intelligence and cybersecurity (secure programming). The training is designed using a variety of modern teaching methods to create a comprehensive and flexible learning experience that aligns with the schedules and circumstances of all trainees. It is structured to be fully remote, but a series of in-person meetings will be held. To ensure the same level of personal support throughout the rest of the training as in the in-person sessions, dedicated trainers will be assigned to follow up with the trainees. Additionally, periodic live meetings and office hours will be available at different times via Zoom to discuss advanced programming topics and provide full support to trainees according to their free time. The training also includes diverse tasks that simulate work environments in global programming companies, ensuring that all trainees are empowered to build large applications efficiently and effectively in the least amount of time and effort.`,
          arb: `يستهدف هذا التدريب المتكامل موظفي القطاع الحكومي وخريجي التخصصات التقنية او طلبة التخصصات التقنية الراغبين في العمل مع القطاعات الحكومية المختلفة كمهندسي او مطوري برمجيات. يغطي التدريب جميع المهارات المطلوبة لبناء أجزاء التطبيق المختلفة وباستخدام  أحدث التقنيات المتاحة عالمياً. كما يغطي التدريب أيضاً اساسيات الذكاء الإصطناعي والأمن السيبراني (البرمجة الاًمنة). تم تصميم هذا التدريب باستخدام مجموعة متنوعة من الأساليب التدريسية الحديثة، لخلق تجربة تعليمية شاملة ومرنة تتماشى مع أوقات وظروف جميع المتدربين. التدريب مصمم ليكون عن بُعد بالكامل ولكن سيتم عقد مجوعة من اللقاءات بشكل وجاهي، ولضمان توفير نفس المستوى من الدعم الشخصي لبقية التدريب كما في التدريب الوجاهي، سيتم تخصيص مدربين لمتابعة المتدربين. بالإضافة إلى ذلك، ستتوفر لقاءات دورية مباشرة وساعات مكتبية في أوقات مختلفة عبر تطبيق زوم، لمناقشة مواضيع متقدمة في البرمجة وتقديم الدعم الكامل للمتدربين بما يتماشى مع أوقات فراغهم. كما يتضمن التدريب مهام متنوعة تحاكي بيئات العمل في شركات البرمجة العالمية، لضمان تمكين جميع المتدربين من بناء تطبيقات ضخمة بكفاءة وفعالية وفي أقل وقت وجهد.`,
        },
        details2: {
          eng: `Previous programming experience is not required since the training in its initial stage covers all the necessary fundamentals`,
          arb: `لا يشترط امتلاك خبرة مسبقة في البرمجة لأن التدريب في مرحلته الأولى سيغطي جميع الأساسيات المطلوبة`,
        },
        internInfo: { eng: "Researcher information", arb: "معلومات الباحث" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        jobCategory: {
          eng: "Intern category",
          arb: "فئة المتدرب",
        },
        interviewQuestions: {
          eng: "General Questions",
          arb: "أسئلة عامة",
        },
        strExplainProposal: {
          arb: "إشرح بإختصار عن مقترحك لتطوير التطبيقات او الخدمات التي تعتقد أنها بحاجه لتطوير",
          eng: "Briefly explain your proposal for developing applications or services that you believe need improvement",
        },
        strExplainIdea: {
          eng: "Please provide a brief explanation of the idea or project you are considering",
          arb: "يرجى  تقديم شرح مختصر عن الفكرة أو المشروع الذي تفكر به",
        },
        intYearExpJs: {
          eng: "Years of experience in JavaScript",
          arb: "عدد سنوات الخبرة في جافا سيكريت",
        },
        intNumJsProject: {
          eng: "The number of projects you have worked on using JavaScript",
          arb: "عدد المشاريع التي عملت عليها في جافاسكربت",
        },
        strExpJsProject: {
          eng: "A brief explanation of your experience in JavaScript",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في جافاسكربت",
        },
        intYearExpFn: {
          eng: "Years of experience in designing client applications",
          arb: "عدد سنوات الخبرة في تصميم تطبيقات العميل",
        },
        strExpFeProject: {
          eng: "A brief explanation of your experience in client application programming",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في برمجة تطبيقات العميل",
        },
        intYearExpNode: {
          eng: "Years of experience in Node.js, Deno, or Bun",
          arb: "عدد سنوات الخبرة في Node.js أو Deno أو Bun",
        },
        intNumNodeProject: {
          eng: "The number of projects you have worked on using Node.js, Deno, or Bun",
          arb: "عدد المشاريع التي عملت عليها في Node.js أو deno أو bun",
        },
        strExpNodeProject: {
          eng: "A brief explanation of your experience with Node.js, Deno, or Bun",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في Node.js أو deno أو bun",
        },
        intYearExpAi: {
          eng: "Years of experience in designing artificial intelligence applications",
          arb: "عدد سنوات الخبرة في تصميم تطبيقات الذكاء الاصطناعي",
        },
        strExpAiProject: {
          eng: "A brief explanation of your experience in artificial intelligence",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في الذكاء الاصطناعي ",
        },
        intYearExpCybS: {
          eng: "Years of experience in designing cybersecurity applications and secure programming applications",
          arb: "عدد سنوات الخبرة في الأمن السيبراني والبرمجة الآمنة",
        },
        strExpCybSProject: {
          eng: "A brief explanation of your experience in cybersecurity applications and secure programming",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في الأمن السيبراني والبرمجة الآمنة ",
        },
        intYearExpMobile: {
          eng: "Years of experience in designing mobile applications",
          arb: "عدد سنوات الخبرة في تصميم تطبيقات الذكية",
        },
        strExpMobileProject: {
          eng: "A brief explanation of your experience in mobile programming",
          arb: "شرح بشكل مختصر عن طبيعة الخبرة في البرمجة التطبيقات الذكية ",
        },
        uploadVideo: {
          arb: "يرجى تقديم فيديو تعريفي عنك يبدأ بتعريف مختصر لنفسك، ثم يبرز أعمالك دون الحاجة لإظهار وجهك، مع الاكتفاء بتسجيل صوتي يرافق عرض الأعمال",
          eng: "Please submit an introductory video that begins with a brief introduction of yourself, followed by a showcase of your work. There is no need to show your face; a voiceover accompanying the display of your work will suffice",
        },
        uploadTranscript: {
          eng: "Please attach your transcript to verify if you are among the top of your class, which will grant you priority for the training opportunity",
          arb: "الرجاء إرفاق كشف علاماتك للتحقق من كونك ضمن أوائل الدفعة، مما سيمنحك أولوية للحصول على فرصة التدريب",
        },
        btn: { eng: "Registration", arb: "تسجيل" },
        video: { eng: "video", arb: "فيديو" },
      },
      login: {
        title: { eng: "Login", arb: "تسجيل الدخول" },
        loginInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        invite: {
          eng: "Welcome to the first Arabic (Kuwaiti) technology platform specializing in providing effective software tools and management systems to help software engineers and programming companies develop secure applications with minimal effort and in the shortest time. We offer training for competencies on using the latest global technologies, support entrepreneurs in turning their innovative ideas into profitable applications, and enhance the capabilities of graduates in technical fields to improve their opportunities in the competitive job market",
          arb: "مرحباً بكم في أول منصة تقنية عربية (كويتية) متخصصة في توفير أدوات برمجية وأنظمة إدارية فعّالة لمساعدة مهندسي البرمجيات وشركات البرمجة على تطوير تطبيقات آمنة بأقل جهد وفي أقصر وقت، تدريب الكفاءات على إستخدام أحدث التقنيات العالمية، دعم رواد الأعمال في تحويل أفكارهم الريادية إلى تطبيقات ربحية، تعزيز قدرة خريجي التخصصات التقنية وتحسين فرصهم في سوق العمل التنافسي",
        },
        btn: { eng: "Login", arb: "تسجيل الدخول" },
        registration: { eng: "Registration", arb: "تسجيل" },
        forgetPassword: { eng: "Forget Password", arb: "نسيت كلمة المرور" },
      },
      restPassword: {
        title: { eng: "ForgetPassword", arb: "نسيت كلمة المرور" },
        subTitle1: { eng: "Enter your email to received the verification code", arb: "الرجاء ادخال بريدك الإلكتروني لإستقبال رمز التحقق" },
        subTitle2: { eng: "Please enter the code sent to your email in the designated field here", arb: "الرجاء إدخال الرمز المرسل إلى بريدك الإلكتروني في الحقل المخصص هنا" },
        subTitle3: { eng: "Enter the new password", arb: "الرجاء ادخال كلمة المرور الجديدة" },
        btnSubmit: { eng: "Submit", arb: "إرسال" },
      },
      regActivityGuest: {
        title: { eng: "Digital Empowerment and Artificial Intelligence Week", arb: "أُسبوع التمكين الرقمي و الذكاء الإصطناعي" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        detailsInfo: {
          eng: "Seminars and Workshops",
          arb: "الندوات وورش العمل",
        },
        detailInfo: {
          eng: "Seminars details",
          arb: "معلومات الندوة",
        },
        subtitle: {
          eng: "Contribute with us to building a prosperous future by nurturing a distinguished generation of leading programmers",
          arb: "ساهم معنا في بناء مستقبل مزدهر من خلال تنمية جيل متميز من المبرمجين المتمكنين",
        },
        dec: {
          eng: "The Digital Transformation and Artificial Intelligence Week in Kuwait will focus on digital transformation strategies and related challenges, as well as explaining artificial intelligence and data protection. It will also showcase modern platforms and tools that enhance work efficiency and develop government applications",
          arb: "أسبوع التحول الرقمي والذكاء الاصطناعي في الكويت سيركز على استراتيجيات التحول الرقمي والتحديات المتعلقة به، فضلاً عن شرح الذكاء الاصطناعي وحماية البيانات. كما سيستعرض المنصات والأدوات الحديثة التي تعزز كفاءة العمل وتطوير التطبيقات الحكومية",
        },
        invite: {
          eng: "We invite you to join us for this leading event to explore new horizons and drive innovation in the world of modern technology",
          arb: "ندعوكم للانضمام إلينا في هذا الحدث الرائد لفتح آفاق جديدة وتحقيق الابتكار في عالم التكنولوجيا الحديثة",
        },
        btn: { eng: "Registration", arb: "تسجيل" },
        btnReg: { eng: "You have registered in the forum", arb: "تم التسجيل في المنتدى" },
        alertThanke: {
          eng: "Thank you for registering for the Digital Empowerment and Artificial Intelligence Forum. We look forward to enriching your experience and maximizing your benefit",
          arb: "نشكركم على تسجيلكم في منتدى التمكين الرقمي ونتطلع إلى إثراء تجربتكم وتحقيق استفادة قصوى",
        },
        alertThankeFutureForum: {
          eng: "You have been successfully registered, and the date and location of the forum will be announced soon.",
          arb: "تم تسجيلك بنجاح، و سيتم الإعلان قريباً عن موعد و مكان المنتدى.",
        },
        alertEndWeek: {
          eng: "We would like to inform you that the Digital Empowerment and Artificial Intelligence Forum has concluded, and registration is now closed. Thank you for your interest and engagement",
          arb: "نود إبلاغكم بأن منتدى التمكين الرقمي والذكاء الاصطناعي قد انتهى، والتسجيل مغلق الآن. نشكركم على اهتمامكم وتفاعلكم",
        },
        targeted: { eng: "Targeted", arb: "الفئات المستهدفة" },
        uniName: { eng: "University name", arb: "اسم الجامعة" },
      },
      sheikProfile: {
        title: { eng: "", arb: "" },
        name: { arb: "الشيخ محمد أحمد الصباح", eng: "" },
      },
      ecertificate: {
        title: { eng: "Preparing certificates", arb: "إعداد الشهادات" },
        label: {
          enterNames: { eng: "Entering names", arb: "إدخال الأسماء" },
          enterCertImg: { eng: "Uploading a copy of the certificate", arb: "تحميل صورة عن الشهادة" },
          selectNameArea: { eng: "selecting the name's location", arb: "اختيار مكان الاسم" },
          selectNameAreaBtn: { eng: "Choose the name's location", arb: "اختر موقع الاسم" },
          selectModalTitle: { eng: "selecting the name's location", arb: "اختيار مكان الاسم" },
          doneBtn: { eng: "Done", arb: "تم" },
          certificateType: { eng: "Certificates type", arb: "نوع الشهادات" },
          prepareCertificate: { eng: "Preparing the certificates", arb: "تجهيز الشهادات" },
          startExecuteBtn: { eng: "Starting the implementation of certifications", arb: "البدء بتنفيذ الشهادات" },
          fetchAPI: { eng: "Fetching data from an API", arb: "جلب البيانات من API" },
          cantFetchFromAPI: {
            eng: "Sorry, but the data cannot be fetched. Please contact the system administrator to verify data access permission",
            arb: "عذراً، لكن لا يمكن جلب البيانات، يرجى مراجعة مسؤول النظام للتحقق من إذن الوصول للبيانات",
          },
        },
      },
      regFormActivity: {
        title: { eng: "Registration and printing of attendance certificate.", arb: "التسجيل و طباعة شهادة الحضور" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        btn: { eng: "Registration", arb: "تسجيل" },
        btnDetails: { arb: "عرض التفاصيل", eng: "View Details" },
        printAttendanceCert: { arb: "طباعة شهادة الحضور", eng: "Printing of attendance certificate" },
        printBadge: { arb: "عرض باجة المؤتمر", eng: "View the conference badge" },
        needToConfirmation: { arb: "يرجى التواصل مع ممثل المنتدى للحصول على رمز التحقق", eng: "Please contact the forum representative to obtain the verification code" },
        needToEnterBadgeID: {
          arb: "يرجى إدخال رقم الباجة المقدم من قبل ممثلي جمعية الذكاء الاصطناعي",
          eng: "Please enter the badge number provided by the representatives of the Artificial Intelligence Association",
        },
        alertNeedToGetBadgeNumber: {
          eng: "The badge number entered is incorrect. Please contact the representatives of the Artificial Intelligence Association to obtain the correct number",
          arb: "رمز التحقق الذي تم إدخاله للباجة غير صحيح، يرجى مراجعة ممثلي جمعية الذكاء الاصطناعي للحصول على الرقم الصحيح",
        },
      },
    },
    stakeholders: {
      admin: {
        mngRegForum: {
          title: { eng: "Conference registration management", arb: "إدارة تسجيل المؤتمرات" },
          confirmation: { deleteSubForm: { eng: "Are you sure you want to delete the sub-forum ?", arb: "هل أنت متأكد من حذف الندوة الفرعية ؟" } },
          label: {
            forumDate: { eng: "Date", arb: "تاريخ الانعقاد" },
            forumLocation: { eng: "Location", arb: "مكان الانعقاد" },
            forumBadge: { eng: "Badge", arb: "الباجه" },
            uploadForumBadge: { eng: "Upload badge image", arb: "تحميل صورة الباجه" },
            uploadForumBoucher: { eng: "Upload Conference Boucher", arb: "تحميل كتيب المؤتمر" },
            forumCertificate: { eng: "Certificate of attendance", arb: "شهادة الحضور" },
            uploadForumCertificate: { eng: "Upload an image of the certificate of attendance", arb: "تحميل صورة عن شهادة الحضور" },
            subForums: { eng: "Sub-forums", arb: "الندوات الفرعية" },
            subForum: { eng: "Sub-forum", arb: "ندوة فرعية" },
            forumLink: { eng: "Registration link", arb: "رابط التسجيل" },
            addForum: { eng: "Add a new conference", arb: "إضافة مؤتمر جديد" },
            editForum: { eng: "Edit conference information", arb: "تعديل معلومات المؤتمر" },
            addSubForum: { eng: "Add a new sub-forum", arb: "إضافة ندوة فرعية جديدة" },
            editSubForum: { eng: "Edit sub-forum information", arb: "تعديل معلومات الندوة الفرعية" },
            numRegGuest: { eng: "The number of registrants for the conference", arb: "عدد المسجلين في المؤتمر" },
            numRegGuestNeedToConfirm: { eng: "The number of registrants for the conference requires approval", arb: "عدد المسجلين في المؤتمر بحاجة لموافقة" },
            mngAttendee: { eng: "Attendee Management", arb: "إدارة الحضور" },
          },
        },
        mngUsers: {
          title: { eng: "Manage User", arb: "أدارة المستخدمين" },
          newUser: { eng: "New User", arb: "مستخدم جديد" },
        },
        manageMedia: {
          title: { eng: "Advertising Video Management", arb: "إدارة الفيديوهات الإعلانية" },
          video: { eng: "video", arb: "فيديو" },
          videoUpload: {
            eng: "You can download a video clip as a model for training or for explaining training objectives",
            arb: "يمكنك تحميل مقطع فيديو كنموذج للتدريب أو للشرح أهداف التدريب",
          },
          point: { eng: "Points", arb: "نقاط" },
          helpMsgPoint: {
            eng: "Please write each point on a separate line, or insert 'Enter' between each point",
            arb: "يرجى كتابة كل نقطة في سطر منفصل، أو إدراج 'Enter' بين كل نقطة",
          },
          intCountView: { eng: "Number of visits to page", arb: "عدد الزيات لصفحة" },
          intCountWatchMedia: { eng: "Number of viewers the video", arb: "عدد مشاهدي الفيديو" },
          videoInfo: { eng: "Video Information", arb: "معلومات الفيديو" },
        },
        mngSurvey: {
          title: {
            eng: "Manage Surveys",
            arb: "ادارة الاستبيانات",
          },
          survey: {
            eng: "Survey",
            arb: "استبيان",
          },
          add: {
            eng: "Add Survey",
            arb: "اضافة الاستبيان",
          },
          edit: {
            eng: "Edit Survey",
            arb: "تعديل الاستبيان",
          },
          questions: {
            eng: "Questions",
            arb: "الأسئلة",
          },
          addQuestion: {
            eng: "Add Question",
            arb: "اضافة سؤال",
          },
          editQuestion: {
            eng: "Edit Question",
            arb: "تعديل سؤال",
          },
          option: {
            eng: "Options",
            arb: "الخيارات",
          },
          responses: {
            eng: "Responses",
            arb: "الإجابات",
          },
          addOptions: {
            eng: "Add Options",
            arb: "اضافة خيار",
          },
          editOptions: {
            eng: "Edit Options",
            arb: "تعديل خيار",
          },
          answersView: { eng: "View Responses", arb: "عرض النتائج" },
          pendingApproval: { eng: "Pending approval", arb: "بحاجة لموافقة" },
          sentAaaiotEmp: { eng: "Send to the Aaiot's employees", arb: "إرسال لموظفي الجمعية" },
          alertOpt: { arb: "الرجاء اضافة على اقل خيارين", eng: "Please add at least two options" },

          forum1: { eng: "You can also view the presentation from the first forum", arb: "كما يمكنكم مشاهدة العرض التقديمي من المنتدى الأول" },

          surveyThank: {
            eng: `We sincerely thank you for your valuable contribution in completing the survey. Your feedback is a crucial element in accelerating the digital transformation process in the State of Kuwait and achieving our shared goals.

In line with the Internet of Things Artificial Intelligence Association's commitment to supporting innovation and entrepreneurship, and emphasizing that digital empowerment begins with developing the skills of our nation’s workforce, we are pleased to announce a free training program specifically for public sector employees.

This training offers a unique opportunity to acquire the essential skills needed to rapidly and easily develop advanced government applications. The program has been designed to accommodate the working hours of employees, providing personalized learning materials for each participant, along with a dedicated trainer, as well as office hours and periodic sessions at various times to ensure maximum benefit.

We encourage you to take advantage of this exceptional opportunity and invite you to register your employees in the program, so you can be part of our joint efforts towards a digitally advanced future for the State of Kuwait.

To register for the next forum, sign up for the free training, learn more details, or attend the presentations from the first forum, please visit the following link.`,
            arb: `نشكركم جزيل الشكر على مساهمتكم القيّمة في تعبئة الاستبيان، إذ تُمثّل ملاحظاتكم عنصراً جوهرياً يُسهم في تسريع عملية التحول الرقمي في دولة الكويت وتحقيق أهدافنا المشتركة. وانطلاقاً من إيمان جمعية الذكاء الاصطناعي للأشياء بأهمية دعم الابتكار وريادة الأعمال، وتأكيداً على أن التمكين الرقمي يبدأ بتطوير مهارات أبناء الوطن، يسرّنا أن نعلن عن برنامج تدريب مجاني مخصّص لموظفي القطاع العام. يُعدّ هذا التدريب فرصة فريدة لاكتساب المهارات الأساسية اللازمة لبناء تطبيقات حكومية متقدمة بسرعة وسهولة. وقد صُمم البرنامج ليتناسب مع طبيعة دوام الموظفين، حيث نوفر مواد تعليمية فردية لكل متدرب مع مدرب متخصص، بالإضافة إلى ساعات مكتبية وجلسات دورية في أوقات مختلفة لضمان تحقيق أقصى استفادة. نحثكم على اغتنام هذه الفرصة المميزة وندعوكم لتسجيل موظفيكم في البرنامج، لتكونوا جزءاً من مسيرتنا المشتركة نحو مستقبل رقمي متقدم لدولة الكويت.

للتسجيل المبدئي في المنتدى القادم أو للتسجيل في التدريب المجاني ومعرفة تفاصيله أو لحضور العروض التقديمية للمنتدى الأول، يُرجى زيارة الرابط التالي.`,
          },
        },
      },
      member: {
        account: {
          sponsorSetter: {
            title: { arb: "المسؤولية الاجتماعية واجب وطني", eng: "Social Responsibility is a National Duty" },
            sponsorInfo: { arb: "معلومات الجهة الداعمة", eng: "Organization Information" },
            supportInfo: { arb: "معلومات الدعم", eng: "Support Information" },
            description: {
              eng: `Supporting initiatives aimed at developing local competencies is a national duty. It reflects their role in community development and sustainability. The AAIOT is committed to launching initiatives focused on enhancing local competencies in technology. Supporting these initiatives will enable the association to expand its reach and provide its free training to a larger number of beneficiaries in Kuwait`,
              arb: `إن دعم المبادرات التي تهدف إلى تطوير الكفاءات المحلية يُعد واجب وطني على الشركات والأفراد، ويعكس دورهم في تنمية المجتمع وتعزيز استدامته. تحرص جمعية الذكاء الاصطناعي للأشياء على تقديم مبادرات موجهة نحو تطوير الكفاءات المحلية في مجال التكنولوجيا، بما يساهم في بناء مستقبل واعد وازدهار مستدام. إن دعم هذه المبادرات سيمكن الجمعية من توسيع نطاقها وتقديم أدواتها ودرواتها المجانية لأكبر عدد من المستفيدين في دولة الكويت، مما يعزز الابتكار ويعمق الأثر الإيجابي على الاقتصاد المحلي`,
            },
            contribution: {
              eng: "Supporting initiatives for local talent development reflects national responsibility, and the AI for Things Association contributes by providing free tools and courses to foster innovation in Kuwait. We value your efforts in bridging the digital divide and increasing the number of specialists in designing smart and secure applications",
              arb: "دعم مبادرات تطوير الكفاءات المحلية يعكس المسؤولية الوطنية، وجمعية الذكاء الاصطناعي للأشياء تساهم عبر تقديم أدوات ودورات مجانية لتعزيز الابتكار في الكويت. نحن نثمن جهودكم في سد الفجوة الرقمية وزيادة عدد المتخصصين في تصميم التطبيقات الذكية والآمنة",
            },
            thanksContribution: {
              eng: "Your presence and support enhance leadership and development in our community. We express our gratitude and appreciation for your contribution to the development of programmers in Kuwait",
              arb: "نشكركم على دعمكم الذي يعزز الريادة ويساهم في تطوير مجتمعنا نحو الأفضل",
            },
            strViewAccountBank: {
              eng: "Please transfer the support amount to the following bank account details of the association",
              arb: "تفاصيل الحساب المصرفي للجمعية الرحاء تحويل المبلغ الدعم إليه",
            },
            logoUpload: {
              eng: "Please provide us with the logo of the supporting organization that will be displayed on the main page of the association's platform, if available",
              arb: "يرجى تزويدنا بشعار الجهة الداعمة الذي سيظهر على الصفحة الرئيسية للمنصة الجمعية، إذا كان متوفرًا",
            },
            receiptUpload: {
              eng: "Please provide the bank receipt after completing the support amount transfer, and we will send you the receipt",
              arb: "يرجى تحميل إيصال البنك بعد إتمام تحويل مبلغ الدعم، وسنرسل لكم سند القبض",
            },
            whatsappUpload: {
              eng: "After completing the support amount transfer, please send the bank receipt via WhatsApp",
              arb: "بعد إتمام تحويل مبلغ الدعم يرجى إرسال إيصال البنك عبر الواتساب ",
            },
            whatsappIsUpload: {
              eng: "Yes, I confirm that all the previous steps have been completed",
              arb: "نعم، أؤكد أنه تم إكمال جميع الخطوات السابقة",
            },
            copyBankInfo: { eng: "Please copy the association's bank details", arb: "الرجاء نسخ المعلومات البنكية للجمعية" },
            trainingCustomNumber: { eng: "Trainees", arb: "متدرب" },
            sponserInfo: {
              supportStatus: { eng: "Support status", arb: "حالة الدعم" },
              supportType: { eng: "Type of support", arb: "نوع الدعم" },
              supportCat: { eng: "Support category", arb: "فئة الدعم" },
              date: { eng: "Support date", arb: "تاريخ الدعم" },
              studentNumber: { eng: "Number of trainees", arb: "عدد المتدربيين" },
              amount: { eng: "Amount", arb: "مقدار الدعم" },
            },
            objStatus: {
              1: { eng: "Under Process", arb: "قيد المعالجة" },
              2: { eng: "Under Process", arb: "قيد المعالجة" },
              3: { eng: "Approved", arb: "تم الموافقة" },
              4: { eng: "Rejected", arb: "تم الرفض" },
            },
            contactopt: {
              eng: "Contact us directly through the available communication channels below",
              arb: "تواصل معنا مباشرة عبر وسائل الاتصال المُتاحة التالية",
            },
            contactopt2: {
              eng: "You can inquire through the following form, and we will contact you at the earliest opportunity",
              arb: "ويُمكنك الإستفسار من خلال النموذج التالي، وسنعاود الاتصال بكم في أقرب فرصة ممكنة",
            },
            receiptCatch: { eng: "Receipt Voucher", arb: "سند قبض" },
            receipt: { eng: "Payment Voucher", arb: "سند إيصال" },
            support: { eng: "Support", arb: "الدعم" },
            btn: { arb: "إبدأ الدعم", eng: "Start Contributing" },

            copyed: { eng: "The association's bank details have been copied to the clipboard", arb: "تم نسخ معلومات البنك الخاصة بالجمعية إلى الحافظة" },
            step1: {
              // stepTirle:{eng: "", arb: "فئة الدعم"},
              title: { eng: "Support category", arb: "فئة الدعم" },
            },
            step2: {
              contactTitle: { eng: "Contact Information", arb: "معلومات التواصل" },
              title: { eng: "Deposit the support amount into the association's bank account", arb: "إيداع المبلغ في الحساب البنكي للجمعية" },
            },
            step3: {
              stepTirle: { eng: "", arb: "" },
              title: {
                eng: "Upload a copy of the bank deposit receipt",
                arb: "تحميل نسخة من وصل الإيداع البنكي",
              },
            },
            blnWebsite: { eng: "Upload a copy of the receipt directly from the website", arb: "تحميل نسخة الوصل من الموقع مباشرة" },
            blnWhatsapp: { eng: "Send a copy of the receipt via WhatsApp", arb: "إرسال نسخة الوصل عبر تطبيق واتساب" },
          },
          traineeSetter: {
            title: { arb: "تدريب مجاني: هندسة البرمجيات الذكية", eng: "Free Fullstack App Development Training" },
            description: {
              eng: `The AAIOT offers comprehensive free training that provides all the necessary skills for developing secure applications that function efficiently and effectively across all browsers and devices. As Kuwait moves towards adopting comprehensive digital empowerment strategies to develop government sectors and improve electronic services, this comprehensive training is a fundamental pillar to ensure the success and sustainability of these strategies`,
              arb: `تقدم جمعية الذكاء الإصطناعي للأشياء تدريب مجاني متكامل يوفر جميع المهارات اللازمة لبناء تطبيقات آمنة تعمل بكفاءة وفاعلية على جميع المتصفحات والأجهزة. ففي ظل توجه الكويت نحو تبني استراتيجيات التمكين الرقمي الشامل لتطوير القطاعات الحكومية وتحسين خدماتها الإلكترونية، يمثل هذا التدريب المتكامل ركيزة أساسية لضمان نجاح واستدامة هذه الاستراتيجيات. فتطوير الكفاءات المحلية ورفع مهاراتها البرمجية يضمن التنفيذ الفعّال للمشاريع التقنية الكبرى، ويوفر الخبرات الضرورية لدعم وتفعيل هذه الاستراتيجيات، بما يسهم في تحقيق الأهداف الوطنية وتعزيز القدرات على المدى البعيد`,
            },
            proofUpload: {
              eng: "Please upload a copy of your transcript",
              arb: "الرجاء تحميل نسخة من كشف علاماتك",
            },
            certificates:{eng:"Training Certificates", arb:"شهادات التدريب"},
            btn: { arb: "الانضمام", eng: "Join" },
            edit: { arb: "تحديث المعلومات", eng: "Update Information" },
            btnDetails: { arb: "تفاصيل التدريب", eng: "Training Details" },
            btntms: { arb: "منصة التدريب", eng: "Training Platform" },

            certificatesSamples:{eng:"Samples of the certificates you will receive upon completing the training", arb:"نماذج عن الشهادات التي ستُمنح عند الانتهاء من التدريب"},
            certificateAttendance:{eng:"Certificate of Attendance and Completion of Training", arb:"شهادة حضور و اتمام التدريب"},
            certificatesAccredited:{eng:"The accredited certificate for training in the labor market", arb:"الشهادة المعتمدة للتدريب في سوق العمل"},

            startDate:{eng:"Training Start Date: ", arb:"تاريخ بدء التدريب: "},
           
            uploadVideo: {
              arb: "يرجى تقديم فيديو تعريفي عنك يبدأ بتعريف مختصر لنفسك، ثم يبرز أعمالك دون الحاجة لإظهار وجهك، مع الاكتفاء بتسجيل صوتي يرافق عرض الأعمال",
              eng: "Please submit an introductory video that begins with a brief introduction of yourself, followed by a showcase of your work. There is no need to show your face; a voiceover accompanying the display of your work will suffice",
            },
          },
          researcherSetter: {
            title: { arb: "ساهم في تعزيز التقدم العلمي ", eng: "Contribute to advancing scientific progress" },
            description: {
              eng: `Research in various fields of technology plays a fundamental role in building a smart and sustainable future, as it drives innovation and provides solutions to growing digital challenges, thereby enhancing the digital economy and sustainable growth. We invite researchers from various disciplines to register and participate in this important research initiative, which brings together elite researchers from diverse fields to support and advance cutting-edge research`,
              arb: `تلعب الأبحاث في مجالات التكنولوجيا المختلفة دورًا أساسيًا في بناء مستقبل ذكي ومستدام، حيث تدفع الابتكار وتقدم حلولًا للتحديات الرقمية المتزايدة، مما يعزز الاقتصاد الرقمي والنمو المستدام. ندعو الباحثين من مختلف التخصصات للتسجيل والمشاركة في هذه المبادرة البحثية الهامة التي تجمع نخبة من الباحثين من شتى المجالات لدعم وتطوير الأبحاث المتقدمة`,
            },

            btn: { arb: "انضم كباحث علمي", eng: "Join as a researcher" },
            uploadVideo: {
              arb: "يرجى تقديم فيديو تعريفي عنك يبدأ بتعريف مختصر لنفسك، ثم يبرز أعمالك دون الحاجة لإظهار وجهك، مع الاكتفاء بتسجيل صوتي يرافق عرض الأعمال",
              eng: "Please submit an introductory video that begins with a brief introduction of yourself, followed by a showcase of your work. There is no need to show your face; a voiceover accompanying the display of your work will suffice",
            },
          },
          MDEASetter: {
            title: { arb: "انضم للجنة التمكين الرقمي", eng: "Join the Digital Empowerment Committee" },
            description: {
              eng: `We are pleased to announce the opening of volunteer opportunities to join the Digital Empowerment Committee, and we invite professionals from all fields to contribute to realizing Kuwait Vision 2035 through this ambitious initiative. The Authority aims to support digital transformation efforts by implementing innovative strategies, developing national technical capabilities, and contributing to the creation of pioneering projects that enhance the digital economy in Kuwait. Volunteering with us is a valuable opportunity to gain practical experience and contribute to strengthening the digital infrastructure and developing innovative solutions that serve various sectors of society. We encourage interested individuals to register and join us in working towards a bright digital future`,
              arb: `يسرنا الإعلان عن فتح باب التطوع للانضمام إلى لجنة التمكين الرقمي، وندعو المتخصصين من جميع المجالات للمساهمة في تحقيق رؤية الكويت 2035 من خلال هذه المبادرة الطموحة. تهدف اللجنة إلى دعم جهود التحول الرقمي من خلال تنفيذ استراتيجيات مبتكرة، تطوير القدرات التقنية الوطنية، والمساهمة في بناء مشاريع ريادية تعزز الاقتصاد الرقمي في الكويت. التطوع معنا يشكل فرصة قيمة لاكتساب خبرات عملية والمساهمة في تعزيز البنية التحتية الرقمية وتطوير حلول مبتكرة تخدم مختلف قطاعات المجتمع. ندعو المهتمين للتسجيل والانضمام إلينا للعمل معًا نحو مستقبل رقمي مشرق`,
            },
            uploadResume: {
              arb: "يرجى رفع السيرة الذاتية الخاص بك",
              eng: "Please upload your resume",
            },
            btn: { arb: "إنضم الآن", eng: "Join now" },
          },
          volunteerSetter: {
            title: { arb: "انضم كمتطوع في الجمعية", eng: "Join as a volunteer in the association" },
            description: {
              eng: `Join as a volunteer with the Artificial Intelligence Association and become part of an innovative body striving to enhance digital empowerment in Kuwait. You will have the opportunity to work with a professional team, allowing you to develop your skills and contribute to pioneering projects that leave a positive impact on the community. Don’t miss the chance to participate in shaping a thriving digital future where creativity meets technology`,
              arb: `انضم كمتطوع إلى جمعية الذكاء الاصطناعي وكن جزءًا من لجنة مبتكرة تسعى لتعزيز التمكين الرقمي في الكويت. ستتاح لك فرصة العمل مع فريق محترف، مما سيمكنك من تطوير مهاراتك والمساهمة في مشاريع رائدة تترك أثراً إيجابياً على المجتمع. لا تفوت فرصة المشاركة في تشكيل مستقبل رقمي مزدهر حيث يلتقي الإبداع بالتكنولوجيا`,
            },
            btn: { arb: "انضم كمتطوع", eng: "Join as a volunteer" },
          },
          runningActivity: {
            btnDetails: { arb: "عرض التفاصيل", eng: "View Details" },
            btnEdit: { arb: "تعديل", eng: "Update" },
            btnReg: { arb: "تسجيل", eng: "Registration" },
            note: { arb: "ملاحظة: ", eng: "Note: " },
            proofUpload: {
              eng: "Please upload a copy of your transcript",
              arb: "الرجاء تحميل نسخة من كشف علاماتك",
            },
            invite: {
              eng: "The Artificial Intelligence Association is pleased to invite you to attend the academic excellence award ceremony at Kuwait University on October 24, 2024",
              arb: "يسر جمعية الذكاء الاصطناعي دعوتكم لحضور حفل التكريم الخاص بتفوقكم الدراسي، وذلك في جامعة الكويت بتاريخ 24 أكتوبر 2024",
            },
            confirmInvitation: {
              eng: "Are you confident about attending the award ceremony on the announced date",
              arb: "هل أنت واثق من حضورك لحفل التكريم في التاريخ المعلن عنه",
            },
            noDate: {
              eng: "The date and location will be determined later",
              arb: "سيتم اختيار التاريخ والمكان في وقت لاحق",
            },
          },
          regActivityGuest: {
            title: { eng: "Digital Empowerment and Artificial Intelligence Week", arb: "أُسبوع التمكين الرقمي و الذكاء الإصطناعي" },
            registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
            detailsInfo: {
              eng: "Seminars and Workshops",
              arb: "الندوات وورش العمل",
            },
            detailInfo: {
              eng: "Seminars details",
              arb: "معلومات الندوة",
            },
            subtitle: {
              eng: "Contribute with us to building a prosperous future by nurturing a distinguished generation of leading programmers",
              arb: "ساهم معنا في بناء مستقبل مزدهر من خلال تنمية جيل متميز من المبرمجين المتمكنين",
            },
            dec: {
              eng: "The Digital Transformation and Artificial Intelligence Week in Kuwait will focus on digital transformation strategies and related challenges, as well as explaining artificial intelligence and data protection. It will also showcase modern platforms and tools that enhance work efficiency and develop government applications",
              arb: "أسبوع التحول الرقمي والذكاء الاصطناعي في الكويت سيركز على استراتيجيات التحول الرقمي والتحديات المتعلقة به، فضلاً عن شرح الذكاء الاصطناعي وحماية البيانات. كما سيستعرض المنصات والأدوات الحديثة التي تعزز كفاءة العمل وتطوير التطبيقات الحكومية",
            },
            invite: {
              eng: "We invite you to join us for this leading event to explore new horizons and drive innovation in the world of modern technology",
              arb: "ندعوكم للانضمام إلينا في هذا الحدث الرائد لفتح آفاق جديدة وتحقيق الابتكار في عالم التكنولوجيا الحديثة",
            },
            btn: { eng: "Registration", arb: "تسجيل" },
            alertThanke: {
              eng: "Thank you for registering for the Digital Empowerment and Artificial Intelligence Week. We look forward to enriching your experience and maximizing your benefit",
              arb: "نشكركم على تسجيلكم في منتدى التمكين الرقمي ونتطلع إلى إثراء تجربتكم وتحقيق استفادة قصوى",
            },
            alertEndWeek: {
              eng: "We would like to inform you that the Digital Empowerment and Artificial Intelligence Week has concluded, and registration is now closed. Thank you for your interest and engagement",
              arb: "نود إبلاغكم بأن أسبوع التمكين الرقمي والذكاء الاصطناعي قد انتهى، والتسجيل مغلق الآن. نشكركم على اهتمامكم وتفاعلكم",
            },
            targeted: { eng: "Targeted", arb: "الفئات المستهدفة" },
          },
          services: {
            title: { eng: "Platforms and Tools", arb: "المنصات والأدوات البرمجية" },
          },
        },
        supportinfo: {
          title: { eng: "Sponsor Information", arb: "معلومات الجهة الداعمة" },
          logoNote: { eng: "Upload the logo that will be displayed on the home page", arb: "تحميل الشعار الذي سيعرض في الصفحة الرئيسية" },
          logo: { eng: "Sponsor Logo", arb: "شعار الجهة الداعمة" },
          receiptCatch: { eng: "Receipt Voucher", arb: "سند قبض" },
          receipt: { eng: "Payment Voucher", arb: "سند إيصال" },
          supportStatus: { eng: "Support status", arb: "حالة الدعم" },
          supportType: { eng: "Type of support", arb: "نوع الدعم" },
          supportCat: { eng: "Support category", arb: "فئة الدعم" },
          date: { eng: "Support date", arb: "تاريخ الدعم" },
          studentNumber: { eng: "Number of trainees", arb: "عدد المتدربيين" },
          amount: { eng: "Amount", arb: "مقدار الدعم" },
          objStatus: {
            1: { eng: "Under Process", arb: "قيد المعالجة" },
            2: { eng: "Under Process", arb: "قيد المعالجة" },
            3: { eng: "Approved", arb: "تم الموافقة" },
            4: { eng: "Rejected", arb: "تم الرفض" },
          },
          support: { eng: "Support", arb: "الدعم" },
        },
      },
      shared: {
        account: {
          title: { eng: "Account", arb: "حسابي" },
        },
        profile: {
          title: { eng: "Profile", arb: "المعلومات الشخصية" },
          proofUpload: {
            eng: "Please provide a receipt proving that you are among the top of the department, certified by the department head",
            arb: "يرجى تقديم شهادة يُثبت أنك من الأوائل في القسم، معتمدًا من رئيس القسم",
          },
          resetPassword: { eng: "Reset Password", arb: "تغيير كلمة المرور" },
          resetEmail: { eng: "Reset Email", arb: "تغيير البريد الإلكتروني" },
          resetPhone: { eng: "Reset Phone", arb: "تغيير رقم الهاتف" },
        },
      },
    },
  },
  shared: {
    app: {
      gov: {
        title: { arb: "قطاع حكومي", eng: "Government sector" },
        targeted: {
          eng: "For Leadership or Supervisory in Information Systems / Employee in Information Systems",
          arb: "الفئه: قيادي او إشرافي في مجال نظم المعلومات / موظف في مجال نظم المعلومات",
        },
        currency: { eng: " Kuwaiti Dinar", arb: " دينار كويتي" },
        dinar: { eng: " Dinar", arb: " دينار" },
      },
      prvt: {
        title: { arb: "موظف قطاع خاص", eng: "Private Sector Employee" },
        targeted: {
          arb: "الفئة: إداري في مجال نظم المعلومات  /مطور او مهندس برمجيات / اكاديمي او باحث / طالب",
          eng: "For Information Systems Manager / Software Developer or Engineer / Academic or Researcher / Student",
        },
      },
      student: {
        title: { arb: "طالب جامعي", eng: "University Student" },
        targeted: {
          arb: "الفئة: إداري في مجال نظم المعلومات  /مطور او مهندس برمجيات / اكاديمي او باحث / طالب",
          eng: "For Information Systems Manager / Software Developer or Engineer / Academic or Researcher / Student",
        },
      },
      unEmployee: {
        title: { arb: "خريج/باحث عن عمل", eng: "Graduate/Job Seeker" },
        targeted: {
          arb: "الفئة: إداري في مجال نظم المعلومات  /مطور او مهندس برمجيات / اكاديمي او باحث / طالب",
          eng: "For Information Systems Manager / Software Developer or Engineer / Academic or Researcher / Student",
        },
      },
      leader: { eng: "Leadership or supervisory", arb: "قيادي او إشرافي" },
      employee: { eng: "Employee", arb: "موظف" },
      manager: { eng: "Chief Executive Officer (CEO) or Chairman", arb: "رئيس مجلس الإدارة أو رئيس تنفيذي" },
      programer: { eng: "Software Developer or Software Engineer", arb: "مطور او مهندس برمجيات" },
      academic: { eng: "Academic or Researcher", arb: "اكاديمي او باحث" },
      // student: { eng: "Student or Entrepreneur", arb: "طالب أو رائد أعمال" },
      sponserLabelCat: {
        main: {
          eng: `Primary Sponsor`,
          arb: `راعي رئيسي`,
        },
        golden: {
          eng: `Golden Sponsors`,
          arb: `راعي ذهبي`,
        },
        silver: {
          eng: `Silver Sponsors`,
          arb: `راعي فضي`,
        },
        bronze: {
          eng: `Bronze Sponsors`,
          arb: `راعي برونزي`,
        },
      },

      currency: { eng: " Kuwaiti Dinar", arb: " دينار كويتي" },
      dinar: { eng: " Dinar", arb: " دينار" },
    },
    buttons: {
      sendBtn: { eng: "Send", arb: "ارسال" },
      submitBtn: { eng: "Submit", arb: "حفظ" },
      readMore: { eng: "Read More", arb: "اقرأ المزيد" },
      save: { eng: "Save", arb: "حفظ" },
      send: { eng: "Send", arb: "ارسال" },
      submit: { eng: "Submit", arb: "ارسال" },
      partnerInfo: { eng: "Partner Information ", arb: "معلومات المسؤول" },

      view: { eng: "View", arb: "عرض" },

      viewGovID: { eng: "View Government ID", arb: "عرض الهوية الشخصية" },
      viewRest: { eng: "View Bank receipt", arb: "عرض الوصل البنكي" },
      rest: { eng: "Rest", arb: "إلغاء الفلتر" },

      approve: { eng: "Approve", arb: "موافقة" },
      regect: { eng: "Reject", arb: "رفض" },
      resubmission: { eng: "Resubmission required files", arb: "إعادة تقديم الملفات المطلوبة" },
      addBtn: { eng: "Add", arb: "إضافة" },
      editBtn: { eng: "Edit", arb: "تعديل" },
      closeBtn: { eng: "Close", arb: "إغلاق" },
      otherBtn: { eng: "Other", arb: "أخرى" },
      update: { eng: "Update", arb: "تحديث" },
      editUpd: { eng: "Update", arb: "تعديل" },
      details: { eng: "Details", arb: "التفاصيل" },
      next: { eng: "Next", arb: "التالي" },
      previous: { eng: "Previous", arb: "السابق" },
      registration: { eng: "Registration", arb: "تسجيل" },

      delete: { eng: "Delete", arb: "حذف" },
    },

    alertFetchNote: {
      alertsurvey: {
        eng: "Thank you very much for completing the survey. Your valuable feedback greatly contributes to accelerating the digital empowerment process in Kuwait",
        arb: "نشكرك جزيل الشكر على تعبئة الاستبيان. ملاحظاتك القيمة تسهم بشكل كبير في تسريع عملية التمكين الرقمي قي دولة الكويت",
      },
      confirmedPartner: {
        eng: "Are you sure the logo has been uploaded and the required fee has been paid",
        arb: "هل أنت متأكد من أن تم رفع الشعار ودفع الرسوم المطلوبة",
      },
      response_Not_Except: {
        eng: "An error has occurred, please use valid information and try again. If you keep getting the same error notification, please contact us via email",
        arb: "حدث خطأ ، يرجى استخدام المعلومات الصحيحة والمحاولة مرة أخرى. إذا استمر ظهور نفس رسالة الخطأ ، فيرجى الاتصال بنا عبر البريد الإلكتروني",
      },
      updated_innfo_Successfully: {
        eng: "Your data has been successfully recorded",
        arb: "لقد تم تسجيل بياناتك بنجاح",
      },
      updated_Successfully2: {
        eng: "Updated Successfully",
        arb: "تم التحديث بنجاح",
      },
      deleteded_Successfully: {
        eng: "Deleteded Successfully",
        arb: "تم الحذف بنجاح",
      },
      added_Successfully: {
        eng: "Added Successfully",
        arb: "اضيف بنجاح",
      },
      verificationCode: {
        eng: "The verification code is incorrect. Please verify your email and enter it accurately",
        arb: "الرمز التحقق غير صحيح، يرجى التحقق من البريد الإلكتروني وإعادة إدخاله بدقة",
      },
      alertEndWeek: {
        eng: "We would like to inform you that the Digital Empowerment and Artificial Intelligence Forum has concluded, and registration is now closed. Thank you for your interest and engagement",
        arb: "نود إبلاغكم بأن منتدى التمكين الرقمي والذكاء الاصطناعي قد انتهى، والتسجيل مغلق الآن. نشكركم على اهتمامكم وتفاعلكم",
      },

      added_Sponser_Successfully: {
        eng: "The sponsor has been successfully added",
        arb: "لقد تم إضافة الجهة الداعمة بننجاح",
      },
      submit_Successfully: {
        eng: "Your request has been successfully received",
        arb: "لقد تم استلام طلبك بنجاح",
      },
      submit_Contact_Successfully: {
        eng: "We would like to thank you for reaching out to us, and we assure you that we will make every effort to respond to you at the earliest opportunity",
        arb: "نود أن نشكرك على اتصالك بنا، ونؤكد لك أننا سنبذل قصارى جهدنا للرد عليك في أقرب فرصة ممكنة",
      },
      cant_Update_Record: {
        eng: "Sorry, the update cannot be completed at this time. Please try again later",
        arb: "عذراً ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقاً",
      },
      cant_Delete_Record: {
        eng: "Sorry, the delete cannot be completed at this time. Please try again later",
        arb: "عذراً ، لا يمكن حذف المعلومات في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقاً",
      },
      cant_Save_Record: {
        eng: "Sorry, can't send the information at this time. Please try again later",
        arb: "عذراً ، لا يمكن ارسال المعلومات في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقاً",
      },
      no_Data_To_Display: {
        eng: "Sorry, but there is no data to display yet",
        arb: "عذراً ، لا توجد أي بيانات لعرضها حتى الآن",
      },
      fetchData_InProgress: {
        eng: "Your request has been successfully submitted and is being processed... please wait",
        arb: "تم تقديم طلبك بنجاح وتتم معالجته ... يرجى الإنتظار",
      },
      invalidLoginInfo: {
        eng: "Invalid credentials. Please try again",
        arb: "بيانات الدخول غير صحيحه. يرجى المحاوله مرة أخرى",
      },
      already_Registered: {
        eng: "You have already registered using this email address",
        arb: "لقد قمت بالتسجيل بالفعل باستخدام عنوان البريد الإلكتروني هذا",
      },
      already_subscrbtion: {
        eng: "You have already subscribed using this email address",
        arb: "لقد قمت بالإشتراك من قبل باستخدام هذا البريد الإلكتروني ",
      },
      email_Already_Used: {
        eng: "This email is already in use. Please enter a different email.",
        arb: "هذا البريد الإلكتروني مستخدم بالفعل. يرجى إدخال بريد إلكتروني آخر.",
      },
      phone_Already_Used: {
        eng: "This number has already been used by another user. Please enter a new phone number",
        arb: "رقم الهاتف مستخدم بالفعل من قبل مستخدم آخر. يرجى إدخال رقم مختلف.",
      },
      "strUserPhone already used by another users!": {
        eng: "This number has already been used by another user. Please enter a new phone number",
        arb: "تم استخدام هذا الرقم مسبقاً من قبل مستخدم آخر. يرجى إدخال رقم هاتف جديد",
      },
      "strUserEmail already used by another users!": {
        eng: "This email has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا البريد الإلكتروني من قبل. الرجاء ادخال بريد الكتروني جديد",
      },
      "strUsername already used by another users!": {
        eng: "This username has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا إسم المستخد من قبل. الرجاء ادخال إسم مستخدم جديد ",
      },
      "Login info you want to changed already used by another user!": {
        phoneNumber: {
          eng: "This number has already been used by another user. Please enter a new phone number",
          arb: "تم استخدام هذا الرقم مسبقاً من قبل مستخدم آخر. يرجى إدخال رقم هاتف جديد",
        },
        email: {
          eng: "This email address has already been used by another user. Please enter a new email address",
          arb: "تم استخدام هذا البريد الإلكتروني مسبقاً من قبل مستخدم آخر. يرجى إدخال بريد إلكتروني جديد",
        },
      },
      email_Not_Used: {
        eng: "This email is not used",
        arb: "هذا البريد الإلكتروني لم يُستخدم من قبل",
      },
      Already_Jion: {
        eng: "We have previously registered as a programmer development supporter. For any inquiries, please contact us",
        arb: "تم التسجيل كداعم لتطوير المبرمجين من قبل. لأي استفسار يرجى التواصل معنا",
      },
      email_CantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later",
        arb: "عذراً ، لا يمكنني إرسال بريدك الإلكتروني في الوقت الحالي. الرجاء إعادة المحاولة لاحقاً",
      },
      exceed_Limit_Of_Email: {
        eng: "Thank you. we received your email",
        arb: "شكرا لك ، لقد تلقينا بريدك الإلكتروني",
      },
      requeioUploadFile: {
        eng: "Please make sure upload required files",
        arb: "يرجى التأكد من تحميل الملفات المطلوبة",
      },
      studentUploadFile: {
        eng: "Please upload a copy of your transcript",
        arb: "الرجاء تحميل نسخة من كشف علاماتك",
      },
      videoUploadFile: {
        eng: "Please upload your introductory video",
        arb: "يرجى رفع الفيديو التعريفي الخاص بك",
      },
      videoUploadFileURL: {
        eng: "The URL code for another video has been used. Please select a different URL code",
        arb: "تم استخدام كود URL لفيديو آخر. يرجى اختيار كود URL جديد",
      },
      surveyURL: {
        eng: "The URL code for another survey has been used. Please select a different URL code",
        arb: "تم استخدام كود URL لاستبيان آخر. يرجى اختيار كود URL جديد",
      },
      resumeUploadFile: {
        eng: "Please upload your resume",
        arb: "يرجى رفع السيرة الذاتية الخاص بك",
      },
      receiptUploadFile: {
        eng: "Please make sure to upload a copy of the deposit receipt for the support amount.",
        arb: "الرجاء تحميل نسخة من إيصال إيداع مبلغ الدعم",
      },
      requeioUploadImage: {
        eng: "Please make sure upload main image",
        arb: "يرجى التأكد من تحميل الصورة الرئيسية",
      },
      invalid_Field: {
        eng: "Please enter all required information correctly",
        arb: "الرجاء ادخال جميع البيانات المطلوبة بشكل صحيح",
      },
      invalid_Field_Email: {
        eng: "Please enter your email address",
        arb: "الرجاء إدخال بريدك الإلكتروني",
      },
      invalid_Verification_Code: {
        eng: "Please enter your verification code",
        arb: "الرجاء ادخال رمز التحقق",
      },
      invalid_Field_Email_phone: {
        eng: "Please enter either your phone number or email address",
        arb: "الرجاء إدخال إما رقم الهاتف أو عنوان البريد الإلكتروني",
      },
      cantFindRecords: {
        eng: "Sorry, But there nothing to view",
        arb: "عذراً ، ولكن لا يوجد شيء لعرضه",
      },
      cantUploadFile: {
        eng: "Sorry, But Can't upload file at this time please try again later",
        arb: "عذراً ، ولكن لا يمكن تحميل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      cantDownloadFile: {
        eng: "Sorry, But Can't download file at this time please try again later",
        arb: "عذراً ، ولكن لا يمكن تنزيل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      contactUs: {
        eng: "تم ارسال طلبك، سيتم التواصل معك في اقرب وقت",
        arb: "Your request has been sent. We will contact you as soon as possible",
      },
      emailVerificationSent: { eng: "Verification code sent to your inbox", arb: "تم إرسال رمز التحقق إلى صندوق الوارد الخاص بك" },
      emailCantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later",
        arb: "عذرا، لا يمكن الإرسال للبريد الإلكتروني الخاص بك في هذا الوقت. الرجاء معاودة المحاولة في وقت لاحق",
      },
      uploadedVideo: { eng: "Please upload the desired video for publication", arb: "الرجاء رفع الفيديو المطلوب للنشر" },
    },
    confirmationMsg: {
      subsecrbtionConf: {
        eng: "An email will be sent to you periodically with the latest news from the association. Do you want to continue?",
        arb: "سيتم إرسال بريد إلكتروني لك بشكل دوري بآخر أخبار الجمعية، هل تريد الإستمرار؟",
      },
      JoinPartnerConf: (trainee, manager) => ({
        eng: `Do you want to confirm the support for these numbers: ${trainee} trainees?`,
        arb: `عدد المتدربين المراد دعمهم ${trainee} هل تريد تأكيد دعم هذه الأعداد؟`,
      }),
      changeStatusTMS: { eng: "Are you sure ?", arb: "هل أنت متأكد؟" },
      changeUserInfo: { eng: "Are you sure about save the new information?", arb: "هل أنت متأكد من حفظ المعلومات الجديدة؟" },
      changePasswordInfo: { eng: "Are you sure you want to change your password?", arb: "هل أنت متأكد أنك تريد تغيير كلمة المرور؟" },
      changeEmailInfo: { eng: "Are you sure you want to change your e-mail address?", arb: "هل أنت متأكد أنك تريد تغيير البريد الإلكتروني؟" },
      changePhoneInfo: { eng: "Are you sure you want to change your phone number?", arb: "هل أنت متأكد أنك تريد تغيير رقم الهاتف؟" },

      unLockUser: { eng: "Are you sure to unlock this user ?", arb: "هل أنت متأكد من فك حظر هذا المستخدم ؟" },
      deleteUser: { eng: "Are you sure to delete this user ?", arb: "هل أنت متأكد من حذف هذا المستخدم ؟" },
      deleteSystem: { eng: "Are you sure to delete this system ?", arb: "هل أنت متأكد من حذف هذا النظام ؟" },
      deleteItem: { eng: "Are you sure to delete this item ?", arb: "هل أنت متأكد من حذف هذا العنصر ؟" },
      deleteMail: { eng: "Are you sure to delete this e-mail address ?", arb: "هل أنت متأكد من حذف هذا البريد الإلكتروني ؟" },
      deleteAllMail: { eng: "Are you sure to delete the selected emails?", arb: "هل أنت متأكد من حذف هذه الرسائل الإلكترونية؟ " },
      deleteFeedback: { eng: "Are you sure to delete this feedback ?", arb: "هل أنت متأكد من حذف هذا التقييم ؟" },
      publishFeedback: { eng: "Are you sure to publish this feedback ?", arb: "هل أنت متأكد من نشر هذا التقييم ؟" },
      unpublishFeedback: { eng: "Are you sure to unpublish this feedback ?", arb: "هل أنت متأكد من عدم نشر هذا التقييم ؟" },

      approve: { eng: "Are you sure to make the applicant a member of the association?", arb: "هل أنت متأكد من جعل مقدم الطلب عضواً في الجمعية؟" },
      approveActive: { eng: "Are you sure to activate the subscription?", arb: "هل أنت متأكد من تفعيل الاشتراك؟" },

      deletemember: { eng: "Are you sure to delete this user?", arb: "هل أنت متأكد من حذف هذا المستخدم ؟" },
      deleteActivity: { eng: "Are you sure to delete this activity?", arb: "هل أنت متأكد من  حذف هذا النشاط ؟" },

      addAsSponser: { eng: "Are you sure about adding him as a sponser?", arb: "هل أنت متأكد من اضافته كراعي؟" },
      addAsStudent: { eng: "Are you sure about adding him as a student?", arb: "هل أنت متأكد من اضافته كطالب؟" },
      addAsResearcher: { eng: "Are you sure about adding him as a researcher?", arb: "هل أنت متأكد من اضافته كباحث؟" },
      addAsVolunteer: { eng: "Are you sure about adding him as a volunteer?", arb: "هل أنت متأكد من اضافته كمتطوع؟" },
      addAsMDEA: { eng: "Are you sure about adding him as a member of the committee?", arb: "هل أنت متأكد من اضافته كعضو في اللجنة؟" },

      addInitialApprovalTraining: {
        eng: "Are you sure about the preliminary approval for this student's admission to the training?",
        arb: "هل أنت متأكد من الموافقة المبدئية لدخول هذا الطالب لتدريب؟",
      },
      addApprovalTraining: {
        eng: "Are you sure about the final approval for this student's admission to the training?",
        arb: "هل أنت متأكد من الموافقة النهائية لدخول هذا الطالب لتدريب؟",
      },
      addRejectTraining: { eng: "Are you sure about refusing this student's admission to the training?", arb: "هل أنت متأكد من رفض دخول هذاالطالب لتدريب؟" },

      publish: { eng: "Are you sure to publish this activity?", arb: "هل أنت متأكد من اعادة نشر هذا النشاط؟" },
      archaic: { eng: "Are you sure to archive the activity?", arb: "هل أنت متأكد من أرشفة هذا النشاط؟" },
      logout: { eng: "Are you sure to log out?", arb: "هل أنت متأكد من تسجيل الخروج؟" },
      yes: { eng: "Yes", arb: "نعم" },
      yesSure: { eng: "Yes, I'm Sure", arb: "نعم أنا متأكد" },
      yesMove: { eng: "Transitioning to the request page", arb: "الانتقال لصفحة الطلب" },
      no: { eng: "No", arb: "لا" },
      procedure: { eng: "Take action", arb: "اتخاذ إجراء" },
      noProcedure: { eng: "No action", arb: "بدون إجراء" },
    },
  },
};
